import { SessionsMetaData } from "./PanelDef";
import React from "react";

import {
  EditComponentProps,
  PanelEditComponent,
  PartialMetaData,
} from "../PanelDef";
import { DropdownItemProps, Icon, Popup, Tab, Table } from "semantic-ui-react";
import {
  EditAnimatedMetaDropdown,
  EditAnimatedMetaInput,
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  EditPanelFormContainer,
} from "../util";
import { AggregateSelector } from "./AggregateSelector";
import * as uuid from "uuid";
import { fetchSessionTypes } from "../../../../../BytebeamClient";

type AggregateRefsType = {
  [key: number]: React.RefObject<AggregateSelector>;
};

export type EditSessionsMetaState = {
  sessionTypesLoading: boolean;
  sessionTypes: string[];
  rowKeys: number[];
  aggregateRefs: AggregateRefsType;
  panelMeta: SessionsMetaData;
  activeIndex: number;
  error: boolean;
  invalidAggregateNames: string[];
};

export class EditSessionsMeta extends PanelEditComponent<
  SessionsMetaData,
  EditSessionsMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  static defaultState(): EditSessionsMetaState {
    return {
      rowKeys: [0],
      aggregateRefs: { 0: React.createRef<AggregateSelector>() },
      activeIndex: 0,
      panelMeta: {
        id: uuid.v4(),
        type: "sessions",
        title: "",
        description: "",
        aggregates: [],
        sessionType: "",
        deviceDashboardIds: [],
      },
      error: false,
      invalidAggregateNames: [],
      sessionTypesLoading: false,
      sessionTypes: [],
    };
  }

  constructor(props: EditComponentProps<SessionsMetaData>) {
    super(props);

    if (props.panelMeta) {
      this.state = this.getStateFromPanelMeta(props);
    } else {
      this.state = EditSessionsMeta.defaultState();
    }
  }

  componentDidMount() {
    const fn = async () => {
      try {
        this.setState({ sessionTypesLoading: true });
        const sessionTypes = await fetchSessionTypes();

        // TODO
        // have to check if the response is empty or undefined
        // We can insert that no session found and add validation to disable submit in this case.
        this.setState({
          sessionTypes: sessionTypes.map((s) => s.name),
        });
      } catch (e) {
        console.error("Error fetching session types", e);
      } finally {
        this.setState({ sessionTypesLoading: false });
      }
    };

    fn();
  }

  getStateFromPanelMeta(
    props: EditComponentProps<SessionsMetaData>
  ): EditSessionsMetaState {
    let rowKeys = props.panelMeta.aggregates.map((_m, i) => i);

    if (rowKeys.length === 0) {
      rowKeys = [0];
    }

    const aggregateRefs = {};

    rowKeys.forEach((k) => {
      aggregateRefs[k] = React.createRef<AggregateSelector>();
    });

    return {
      panelMeta: props.panelMeta,
      rowKeys,
      aggregateRefs,
      activeIndex: 0,
      sessionTypesLoading: false,
      sessionTypes: [],
      error: false,
      invalidAggregateNames: [],
    };
  }

  getPanelMeta(type): PartialMetaData<SessionsMetaData> {
    const validAggregates = this.state.rowKeys
      .map((key) => this.state.aggregateRefs[key])
      .filter((ref) => {
        return ref && ref.current && ref.current.isFilled();
      })
      .map((ref) => {
        if (!ref.current) {
          throw new Error("Should not happen");
        }

        return ref.current.getAggregate();
      });

    // Create a frequency map to track the count of each aggregate name
    const aggregateFrequencyMap = new Map();

    validAggregates.forEach((aggregate) => {
      const aggregateName = aggregate.name;
      const count = aggregateFrequencyMap.get(aggregateName) || 0;
      aggregateFrequencyMap.set(aggregateName, count + 1);
    });

    const invalidAggregateNames = validAggregates.reduce(
      (acc, aggregate) => {
        const aggregateName = aggregate.name;
        if (aggregateFrequencyMap.get(aggregateName) > 1) {
          // If the name is not unique and not already in the accumulator, add it
          if (!acc.includes(aggregateName)) {
            acc.push(aggregateName);
          }
        } else {
          // If the name is unique, remove it from the accumulator if it exists
          const index = acc.indexOf(aggregateName);
          if (index !== -1) {
            acc.splice(index, 1);
          }
        }
        return acc;
      },
      [...this.state.invalidAggregateNames]
    );

    this.setState({ invalidAggregateNames });

    const meta: SessionsMetaData = {
      type: "sessions",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      aggregates: validAggregates,
      sessionType: this.state.panelMeta.sessionType,
      deviceDashboardIds: this.state.panelMeta.deviceDashboardIds,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
      errorMessage:
        invalidAggregateNames.length > 0
          ? "Please check repeating aggregate names."
          : undefined,
    };
  }

  isValidPanelMeta(meta: SessionsMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (
      (!!!meta.sessionType && type === "submit") ||
      (this.state.invalidAggregateNames.length > 0 && type === "submit")
    ) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.sessionType && this.state.invalidAggregateNames.length === 0;
  }

  addRow() {
    this.setState(({ rowKeys, aggregateRefs }) => {
      const newRowKey = Math.max(...rowKeys) + 1;
      const newRefs = Object.assign({}, aggregateRefs, {
        [newRowKey]: React.createRef(),
      });

      return {
        rowKeys: [...rowKeys, newRowKey],
        aggregateRefs: newRefs,
      };
    });
  }

  removeRow(i: number) {
    delete this.state.aggregateRefs[i];

    this.setState({
      rowKeys: this.state.rowKeys.filter((v) => v !== i),
      aggregateRefs: this.state.aggregateRefs,
    });
  }

  setSessionType(value: string) {
    this.setState({
      panelMeta: Object.assign({}, this.state.panelMeta, {
        sessionType: value,
      }),
    });
  }

  setDashboardIdsColumn(value: string[]) {
    this.setState({
      panelMeta: Object.assign({}, this.state.panelMeta, {
        deviceDashboardIds: value,
      }),
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const rowKeys = this.state.rowKeys;

    const sessionTypeOptions = this.state.sessionTypes.map((s) => {
      return {
        key: s,
        value: s,
        text: s,
      };
    });

    let filteredDeviceDashboards: any = this.props.dashboards?.filter(
      (item) => {
        if (item.type === "fleet") {
          return false;
        } else return true;
      }
    );

    const deviceDashboardIdOptions: DropdownItemProps[] =
      filteredDeviceDashboards.map((item) => {
        const obj = {
          key: String(item.id),
          value: String(item.id),
          text: `${item.id} - ${item.title}`,
        };
        return obj;
      });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditAnimatedMetaDropdown
                selection
                search
                loading={this.state.sessionTypesLoading}
                placeholder="Session Type"
                text={this.state.panelMeta.sessionType}
                options={sessionTypeOptions}
                onChange={(_event, data) =>
                  this.setSessionType(data.value as string)
                }
                defaultValue={this.state.panelMeta.sessionType}
                elementid={"sessionType"}
                error={this.state.error && !this.state.panelMeta.sessionType}
              />
              <ThinDivider />
              <Table>
                <Table.Body>
                  {rowKeys.map((rowKey, i) => {
                    return (
                      <React.Fragment key={rowKey}>
                        {i !== 0 ? <ThinDivider /> : <></>}
                        <AggregateSelector
                          key={rowKey}
                          ref={this.state.aggregateRefs[rowKey]}
                          defaultValue={this.state.panelMeta.aggregates[i]}
                          showRemoveIcon={rowKeys.length !== 1 || i > 0}
                          showAddIcon={i === rowKeys.length - 1}
                          onRemoveRow={() => this.removeRow(rowKey)}
                          onAddRow={this.addRow.bind(this)}
                          tables={this.props.tables}
                          elementid={i.toString()}
                          invalidAggregateNames={
                            this.state.invalidAggregateNames
                          }
                        />
                      </React.Fragment>
                    );
                  })}
                </Table.Body>
              </Table>
              <ThinDivider />
              <EditMetaRow>
                <div style={{ width: "48%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Device dashboard links"
                    search
                    selection
                    multiple
                    options={deviceDashboardIdOptions}
                    onChange={(_event, data) =>
                      this.setDashboardIdsColumn(data.value as string[])
                    }
                    value={this.state.panelMeta.deviceDashboardIds || []}
                    elementid={"LocateDevicesDashboardID"}
                  />
                </div>{" "}
                <Popup
                  inverted
                  content="Dashboard to view the selected device data"
                  trigger={
                    <Icon style={{ marginLeft: "5px" }} name="info circle" />
                  }
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
