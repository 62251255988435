import { AlertsMetaData } from "./PanelDef";
import React from "react";

import {
  EditComponentProps,
  PanelEditComponent,
  PartialMetaData,
} from "../PanelDef";
import {
  Dropdown,
  DropdownDivider,
  DropdownItemProps,
  Input,
  Label,
  Tab,
  Table,
} from "semantic-ui-react";
import {
  EditAnimatedMetaInput,
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  EditPanelFormContainer,
  ToggleLabel,
} from "../util";
import ToggleSwitch from "../../../common/ToggleSwitch";
import {
  TableInfo,
  fetchAlertGroups,
  fetchAlertRules,
} from "../../../../../BytebeamClient";
import { AggregateSelector } from "../Sessions/AggregateSelector";
import { beamtoast } from "../../../../common/CustomToast";

type AggregateRefsType = {
  [key: number]: React.RefObject<AggregateSelector>;
};

export type EditAlertsMetaState = {
  tabbedView: boolean;
  tabList: string[] | "all";
  tabByAlertGroups: boolean;
  tabOptions: DropdownItemProps[];
  rowKeys: number[];
  aggregateRefs: AggregateRefsType;
  error: boolean;
  invalidAggregateNames: string[];
};

export type EditAlertsMetaProps = {
  panelMeta: AlertsMetaData;
  tables: TableInfo;
};

export class EditAlertsMeta extends PanelEditComponent<
  AlertsMetaData,
  EditAlertsMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  static defaultState(): EditAlertsMetaState {
    return {
      tabbedView: false,
      tabByAlertGroups: false,
      tabList: "all",
      tabOptions: [],
      rowKeys: [0],
      invalidAggregateNames: [],
      error: false,
      aggregateRefs: { 0: React.createRef<AggregateSelector>() },
    };
  }

  constructor(props: EditAlertsMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = this.getStateFromPanelMeta(props);
    } else {
      this.state = EditAlertsMeta.defaultState();
    }
  }

  getStateFromPanelMeta(
    props: EditComponentProps<AlertsMetaData>
  ): EditAlertsMetaState {
    const aggregates = props.panelMeta.aggregates ?? [];
    let rowKeys = aggregates.map((_m, i) => i);

    if (rowKeys.length === 0) {
      rowKeys = [0];
    }

    const aggregateRefs = {};

    rowKeys.forEach((k) => {
      aggregateRefs[k] = React.createRef<AggregateSelector>();
    });

    return {
      rowKeys,
      aggregateRefs,
      tabbedView: this.props.panelMeta.tabbedView ?? false,
      tabByAlertGroups: this.props.panelMeta.tabByAlertGroups ?? false,
      tabList: this.props.panelMeta.tabList ?? "all",
      invalidAggregateNames: [],
      error: false,
      tabOptions: [],
    };
  }

  getAlertValue(isByGroup: boolean) {
    const { tabList } = this.state;

    if (tabList === "all") {
      return undefined;
    }
    return isByGroup ? tabList : undefined;
  }

  getPanelMeta(type): PartialMetaData<AlertsMetaData> {
    const validAggregates = this.state.rowKeys
      .map((key) => this.state.aggregateRefs[key])
      .filter((ref) => {
        return ref && ref.current && ref.current.isFilled();
      })
      .map((ref) => {
        if (!ref.current) {
          throw new Error("Should not happen");
        }

        return ref.current.getAggregate();
      });

    // Create a frequency map to track the count of each aggregate name
    const aggregateFrequencyMap = new Map();

    validAggregates.forEach((aggregate) => {
      const aggregateName = aggregate.name;
      const count = aggregateFrequencyMap.get(aggregateName) || 0;
      aggregateFrequencyMap.set(aggregateName, count + 1);
    });

    const invalidAggregateNames = validAggregates.reduce(
      (acc, aggregate) => {
        const aggregateName = aggregate.name;
        if (aggregateFrequencyMap.get(aggregateName) > 1) {
          // If the name is not unique and not already in the accumulator, add it
          if (!acc.includes(aggregateName)) {
            acc.push(aggregateName);
          }
        } else {
          // If the name is unique, remove it from the accumulator if it exists
          const index = acc.indexOf(aggregateName);
          if (index !== -1) {
            acc.splice(index, 1);
          }
        }
        return acc;
      },
      [...this.state.invalidAggregateNames]
    );

    this.setState({ invalidAggregateNames });

    const meta: AlertsMetaData = {
      type: "alerts",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      aggregates: validAggregates,
      tabbedView: this.state.tabbedView,
      tabList: this.state.tabList,
      alert_groups: this.getAlertValue(this.state.tabByAlertGroups),
      alert_names: this.getAlertValue(!this.state.tabByAlertGroups),
      tabByAlertGroups: this.state.tabByAlertGroups,
      page: this.props.panelMeta.page,
      limit: this.props.panelMeta.limit,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
      errorMessage:
        invalidAggregateNames.length > 0
          ? "Please check repeating aggregate names."
          : undefined,
    };
  }

  isValidPanelMeta(meta: AlertsMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (this.state.invalidAggregateNames.length > 0 && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return this.state.invalidAggregateNames.length === 0;
  }

  addRow() {
    this.setState(({ rowKeys, aggregateRefs }) => {
      const newRowKey = Math.max(...rowKeys) + 1;
      const newRefs = Object.assign({}, aggregateRefs, {
        [newRowKey]: React.createRef(),
      });

      return {
        rowKeys: [...rowKeys, newRowKey],
        aggregateRefs: newRefs,
      };
    });
  }

  removeRow(i: number) {
    delete this.state.aggregateRefs[i];

    this.setState({
      rowKeys: this.state.rowKeys.filter((v) => v !== i),
      aggregateRefs: this.state.aggregateRefs,
    });
  }

  tabbedViewToggle() {
    const toggled = !this.state.tabbedView;
    this.setState({ tabbedView: toggled });
  }

  tabTypeToggle() {
    const tabByAlertGroups = !this.state.tabByAlertGroups;
    this.setState({ tabByAlertGroups, tabList: "all" });
  }

  async fetchAlertTabs() {
    if (this.state.tabByAlertGroups) {
      const res = await fetchAlertGroups();

      this.setState({
        tabOptions: [
          {
            text: "All",
            key: "all",
            value: "all",
            onClick: (event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault();
              this.setState({ tabList: "all" });
            },
          },
          {
            text: "divider",
            value: "divider",
            content: <DropdownDivider />,
            disabled: true,
          },
          ...res.map((alertGroup) => {
            return {
              key: alertGroup,
              text: alertGroup,
              value: alertGroup,
            };
          }),
        ],
      });
    } else {
      const res = await fetchAlertRules();

      this.setState({
        tabOptions: [
          {
            text: "All",
            key: "all",
            value: "all",
            onClick: (event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault();
              this.setState({ tabList: "all" });
            },
          },
          {
            text: "divider",
            value: "divider",
            content: <DropdownDivider />,
            disabled: true,
          },
          ...res.map((alertRule) => {
            return {
              key: alertRule.id,
              text: alertRule.name,
              value: alertRule.name,
            };
          }),
        ],
      });
    }
  }

  handleTabListChange(newValue: string[] | "all") {
    const currentlyAll = this.state.tabList === "all";
    let value: string[] | "all" = "all" as const;
    if (currentlyAll) {
      if (newValue.length === 0) {
        value = "all";
        beamtoast.error(
          `Either select All or at least one Alert ${this.state.tabByAlertGroups ? "Group" : "Rule"}`
        );
      } else value = (newValue as string[]).filter((v) => v !== "all");
    } else if (
      (newValue as string[]).includes("all") ||
      newValue.includes("all") ||
      newValue.length === 0
    )
      value = "all" as const;
    else value = newValue as string[];

    this.setState({
      tabList: value,
    });
  }

  componentDidMount(): void {
    this.fetchAlertTabs();
  }

  componentDidUpdate(
    prevProps: Readonly<EditComponentProps<AlertsMetaData>>,
    prevState: Readonly<EditAlertsMetaState>,
    snapshot?: any
  ): void {
    if (prevState.tabByAlertGroups !== this.state.tabByAlertGroups) {
      this.fetchAlertTabs();
    }
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const rowKeys = this.state.rowKeys;

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Enable Tabbed View
                </ToggleLabel>
                <ToggleSwitch
                  id="tabbedView"
                  defaultChecked={this.props.panelMeta.tabbedView}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.tabbedViewToggle();
                  }}
                />
              </EditMetaRow>
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Group by Alert Groups
                </ToggleLabel>
                <ToggleSwitch
                  id="tabType"
                  defaultChecked={this.props.panelMeta.tabByAlertGroups}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => this.tabTypeToggle()}
                />
              </EditMetaRow>
              <EditMetaRow style={{ marginTop: "2rem" }}>
                <Input labelPosition="left">
                  <Label>
                    Filter Alert{" "}
                    {this.state.tabByAlertGroups ? "Group" : "Rule"} Tabs
                  </Label>
                  <Dropdown
                    style={{ minWidth: "300px" }}
                    placeholder={`Select Alert ${this.state.tabByAlertGroups ? "Group" : "Rule"}`}
                    options={this.state.tabOptions}
                    fluid
                    search
                    selection
                    multiple
                    loading={this.state.tabOptions.length === 0}
                    value={
                      this.state.tabList === "all"
                        ? ["all"]
                        : this.state.tabList
                    }
                    onChange={(_event, data) => {
                      this.handleTabListChange(data.value as string[]);
                    }}
                  />
                </Input>
              </EditMetaRow>
              <ThinDivider />
              <Table>
                <Table.Body>
                  {rowKeys.map((rowKey, i) => {
                    return (
                      <React.Fragment key={rowKey}>
                        {i !== 0 ? <ThinDivider /> : <></>}
                        <AggregateSelector
                          key={rowKey}
                          ref={this.state.aggregateRefs[rowKey]}
                          defaultValue={
                            this.props.panelMeta.aggregates
                              ? this.props.panelMeta.aggregates[i]
                              : undefined
                          }
                          showRemoveIcon={rowKeys.length !== 1 || i > 0}
                          showAddIcon={i === rowKeys.length - 1}
                          onRemoveRow={() => this.removeRow(rowKey)}
                          onAddRow={this.addRow.bind(this)}
                          tables={this.props.tables}
                          elementid={i.toString()}
                          invalidAggregateNames={
                            this.state.invalidAggregateNames
                          }
                        />
                      </React.Fragment>
                    );
                  })}
                </Table.Body>
              </Table>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
