import React, { useState, useRef, useCallback } from "react";
import { Icon, Input, Popup, Table } from "semantic-ui-react";
import Layout from "../../common/Layout";
import useAsyncEffect from "../../common/useAsyncEffect";
import * as uuid from "uuid";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import CreateMetadataButton from "./CreateMetadataButton";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  ButtonIcon,
  DisplayIf,
  HARDWARE_TYPES,
  hasSpecialCharacters,
} from "../../util";
import {
  createMetadataKey,
  deleteMetadataKey,
  editMetadataKey,
  fetchAllMetadataKeys,
  updateTenantSettings,
} from "../../../../BytebeamClient";
import { useUser } from "../../../../context/User.context";
import LoadingAnimation from "../../../common/Loader";
import { TenantSettings } from "../../../../util";
import { beamtoast } from "../../../common/CustomToast";

interface DraftInputProps {
  defaultValue: string;
  onSave: (value: string) => Promise<void> | void;
  metadataKeysSet: Set<string>;
}

const DraftInput = (props: DraftInputProps) => {
  const [value, setValue] = useState(props.defaultValue);

  const validateMetadataValue = async () => {
    let metadataKey = value.trim();
    let metadataKeysSet = props.metadataKeysSet;
    if (props.defaultValue !== "") metadataKeysSet.delete(props.defaultValue); // Removed props passed metadata key from set to avoid duplicate role name error on edit

    if (metadataKey === "") {
      beamtoast.error("Metadata key cannot be empty");
    } else if (metadataKeysSet.has(metadataKey)) {
      beamtoast.error("Metadata key already exists");
    } else if (metadataKey.toLowerCase() === "status") {
      beamtoast.error("Metadata key cannot be 'status'");
    } else if (metadataKey === props.defaultValue) {
      beamtoast.error(
        "Metadata key cannot be same as previous key, press cancel"
      );
    } else if (hasSpecialCharacters(metadataKey)) {
      beamtoast.error(
        "Metadata key cannot contain special characters except underscore"
      );
    } else await props.onSave(metadataKey);
  };

  return (
    <>
      <Input
        value={value}
        onKeyPress={async (event) => {
          if (event.key === "Enter") await validateMetadataValue();
        }}
        style={{ marginRight: "5px" }}
        onChange={(e) => setValue(e.target.value?.replace(" ", "_"))}
      />
      <Icon
        link
        name="save"
        onClick={async () => await validateMetadataValue()}
      />
    </>
  );
};

interface MetadataKeyRow {
  key: string;
  id: string;
  editable: boolean;
  state:
    | "READY"
    | "DRAFT_EDIT"
    | "CREATING"
    | "DELETING"
    | "UPDATING"
    | "ERROR";
  errorMsg?: string;
}

type Reducer = (rows: MetadataKeyRow[]) => MetadataKeyRow[];

export default function Metadatakeys() {
  const [metadataKeys, setMetadataKeys] = useState<MetadataKeyRow[]>([]);
  const [metadataKeysSet, setMetadataKeysSet] = useState<Set<string>>(
    new Set()
  );
  const latestRows = useRef<MetadataKeyRow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [metadataKeysVersion, setMetadataKeysVersion] = useState(0);

  const { user, getCurrentUser } = useUser();

  latestRows.current = metadataKeys;

  const permissions = user.role.permissions;
  const tenant_settings: TenantSettings = user["tenant-settings"] ?? {
    common_settings: {
      pin_metadata: [],
    },
    "serial-key": null,
    dashboard_settings: {
      custom_time_ranges: {},
    },
    hardware_type: HARDWARE_TYPES[0],
    show_tabs: null,
    logo: {
      light: "",
      dark: "",
    },
    favicon: {
      light: "",
      dark: "",
    },
  };
  const common_settings = tenant_settings?.common_settings ?? {};
  const pinnedMetadataKeys: Array<string> = common_settings?.pin_metadata ?? [];

  const apply = useCallback(
    (reducer: Reducer) => {
      const newRows = reducer(latestRows.current);
      setMetadataKeys(newRows);
    },
    [latestRows]
  );

  useAsyncEffect(async () => {
    document.title = "Metadata | Bytebeam";
    setLoading(true);
    try {
      const keys = await fetchAllMetadataKeys();
      setMetadataKeysSet(new Set(keys.map((metadata) => metadata.key)));
      const rows: MetadataKeyRow[] = keys.map(({ key }) => {
        return {
          key,
          id: uuid.v4(),
          editable: true,
          state: "READY",
        };
      });
      apply((ignore) => [...rows]);

      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
    // }, [metadataKeys?.length]);
  }, [metadataKeysVersion]);
  // Even though there is a dependency on addCreateRow, we don't want to
  // refresh table every-time addCreateRow gets updated, as the behavior
  // will be same.

  const replaceRow = useCallback(
    (newRow: MetadataKeyRow) => {
      const updatedRow = [...metadataKeys, newRow.key] as MetadataKeyRow[];
      setMetadataKeys(updatedRow);
      let oldRow: MetadataKeyRow | undefined;
      apply((rows) =>
        rows.map((row) => {
          if (row.id === newRow.id) {
            oldRow = row;
            return newRow;
          } else {
            return row;
          }
        })
      );
      return oldRow;
    },
    [apply] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const replaceRowAndAdd = useCallback(
    (replaceRow: MetadataKeyRow) => {
      let oldRow: MetadataKeyRow | undefined;
      apply((rows) =>
        rows.map((row) => {
          if (row.id === replaceRow.id) {
            oldRow = row;
            return replaceRow;
          } else {
            return row;
          }
        })
      );
      return oldRow;
    },
    [apply]
  );

  const createKey = useCallback(
    (id: string) => {
      return async (key: string) => {
        replaceRowAndAdd({
          key,
          id,
          editable: false,
          state: "CREATING",
        });
        try {
          const res = await createMetadataKey({ key });
          if (res.result === key) {
            setMetadataKeysVersion((version) => version + 1);
            replaceRow({
              key,
              id,
              editable: true,
              state: "READY",
            });

            // Updating the user state in the context to reflect the new permissions
            await getCurrentUser();
          } else {
            replaceRow({
              key,
              id,
              editable: true,
              state: "ERROR",
              errorMsg:
                "Unexpected create result: " + JSON.stringify(res.result),
            });
          }
        } catch (e) {
          replaceRow({
            key,
            id,
            editable: true,
            state: "ERROR",
            errorMsg: "Failed to create metadata",
          });
        }
      };
    },
    [replaceRow, replaceRowAndAdd] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateKey = useCallback(
    (id: string) => {
      return async (key: string) => {
        const oldRow = replaceRow({
          key,
          id,
          editable: false,
          state: "UPDATING",
        });

        if (!oldRow) {
          replaceRow({
            id,
            key,
            editable: true,
            state: "ERROR",
            errorMsg: "Internal error, update on missing row",
          });
          return;
        }
        try {
          const res = await editMetadataKey(oldRow.key, { key });
          if (res.result === key) {
            setMetadataKeysVersion((version) => version + 1);
            replaceRow({
              key,
              id,
              editable: true,
              state: "READY",
            });

            // Updating the updated metadata key from the user tenant_settings pinnedMetadataKeys if it pinned
            if (pinnedMetadataKeys.includes(oldRow.key))
              await updateTenantSettings({
                settings: {
                  ...tenant_settings,
                  common_settings: {
                    ...common_settings,
                    pin_metadata: [
                      ...pinnedMetadataKeys.filter((key) => key !== oldRow.key),
                      key,
                    ],
                  },
                },
              });

            beamtoast.success(
              `Metadata key '${oldRow.key}' to '${key}' updated successfully`
            );
            // Updating the user state in the context to reflect the new permissions
            await getCurrentUser();
          } else {
            beamtoast.error(`Failed to update metadata key '${oldRow.key}'`);
            replaceRow({
              key,
              id,
              editable: true,
              state: "ERROR",
              errorMsg: "Unexpected update result: " + JSON.stringify(res),
            });
          }
        } catch (e) {
          console.log(e);
          beamtoast.error(`Failed to update metadata key '${oldRow.key}'`);
          replaceRow({
            key,
            id,
            editable: true,
            state: "ERROR",
            errorMsg: e as string,
          });
        }
      };
    },
    [replaceRow] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleEdit = useCallback(
    (mk: MetadataKeyRow) => {
      replaceRow({
        ...mk,
        state: "DRAFT_EDIT",
        editable: false,
      });
    },
    [replaceRow] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCancel = useCallback(
    (mk: MetadataKeyRow) => {
      if (mk.state === "DRAFT_EDIT") {
        replaceRow({
          ...mk,
          state: "READY",
          editable: true,
        });
      }
    },
    [apply, replaceRow] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleDelete = useCallback(
    (mk: MetadataKeyRow) => {
      return async () => {
        replaceRow({
          ...mk,
          state: "DELETING",
          editable: false,
        });
        try {
          await deleteMetadataKey(mk.key);
          setMetadataKeysVersion((version) => version + 1);
          apply((rows) => rows.filter((row) => row.id !== mk.id));

          // Removing the deleted metadata key from the user tenant_settings pinnedMetadataKeys if it exists
          if (pinnedMetadataKeys.includes(mk.key))
            await updateTenantSettings({
              settings: {
                ...tenant_settings,
                common_settings: {
                  ...common_settings,
                  pin_metadata: [
                    ...pinnedMetadataKeys.filter((key) => key !== mk.key),
                  ],
                },
              },
            });

          beamtoast.success(`Metadata key '${mk.key}' deleted successfully`);
          // Updating the user state in the context to reflect the new permissions
          await getCurrentUser();
        } catch (e) {
          console.log(e);
          beamtoast.error(`Failed to delete metadata key '${mk.key}'`);
          replaceRow({
            ...mk,
            editable: true,
            state: "ERROR",
            errorMsg: e as string,
          });
        }
      };
    },
    [apply, replaceRow] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (errorOccurred) {
    return <ErrorMessage marginTop="280px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading metadata"
      />
    );
  }
  return (
    <>
      <Layout
        buttons={
          <DisplayIf cond={permissions.editMetadataKeys}>
            <CreateMetadataButton
              createKey={createKey}
              updateKey={updateKey}
              metadataKeysSet={metadataKeysSet}
            />
          </DisplayIf>
        }
      >
        <Table fixed celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Key</Table.HeaderCell>
              <DisplayIf cond={permissions.editMetadataKeys}>
                <Table.HeaderCell>Ops</Table.HeaderCell>
              </DisplayIf>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {metadataKeys.length !== 0 ? (
              metadataKeys.map((mk) => (
                <Table.Row key={mk.id}>
                  <Table.Cell>
                    {mk.state === "READY" && mk.key}
                    {/* TODO style appropriately */}
                    {mk.state === "CREATING" && <b>{mk.key}</b>}
                    {mk.state === "UPDATING" && <i>{mk.key}</i>}
                    {mk.state === "DELETING" && mk.key}
                    {mk.state === "DRAFT_EDIT" &&
                      permissions.editMetadataKeys && (
                        <DraftInput
                          defaultValue={mk.key}
                          onSave={updateKey(mk.id)}
                          metadataKeysSet={metadataKeysSet}
                        />
                      )}
                    {mk.state === "DRAFT_EDIT" &&
                      permissions.editMetadataKeys && (
                        <Icon
                          name="cancel"
                          link
                          onClick={() => handleCancel(mk)}
                        />
                      )}
                  </Table.Cell>
                  <DisplayIf cond={permissions.editMetadataKeys}>
                    <Table.Cell>
                      {mk.editable && (
                        <>
                          {mk.key ===
                          user?.["tenant-settings"]?.["serial-key"] ? (
                            <>
                              <Popup
                                trigger={
                                  <ButtonIcon
                                    link
                                    name="edit"
                                    disabled={
                                      mk.key ===
                                      user?.["tenant-settings"]?.["serial-key"]
                                    }
                                  />
                                }
                                content={"Metadata is assigned as serial-key"}
                                inverted
                                position="top center"
                              />
                              <Popup
                                trigger={
                                  <ButtonIcon
                                    link
                                    name="trash"
                                    disabled={
                                      mk.key ===
                                      user?.["tenant-settings"]?.["serial-key"]
                                    }
                                  />
                                }
                                content={"Metadata is assigned as serial-key"}
                                inverted
                                position="top center"
                              />
                            </>
                          ) : (
                            <>
                              <Icon
                                name="edit"
                                link
                                onClick={() => handleEdit(mk)}
                              />
                              <ConfirmationModal
                                prefixContent="Delete metadata key"
                                expectedText={mk.key}
                                onConfirm={handleDelete(mk)}
                                message={
                                  <ConfirmationModalMessage
                                    name={mk.key}
                                    type={"Metadata Key"}
                                    specialMessage=""
                                  />
                                }
                                trigger={<Icon link name="trash" />}
                              />
                            </>
                          )}
                        </>
                      )}
                    </Table.Cell>
                  </DisplayIf>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colspan={permissions.editMetadataKeys ? "2" : "1"}>
                  <ErrorMessage
                    marginTop="30px"
                    message={"No Metadata Found!"}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Layout>
    </>
  );
}
