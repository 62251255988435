import React, { useEffect, useState } from "react";
import { Button, Modal, Table, TableHeaderCell } from "semantic-ui-react";
import LoadingAnimation from "../../../common/Loader";
import { ParsedDBCResponse } from "../../../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";

interface CreateDBCModalProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly parsedDBC?: ParsedDBCResponse;
}

export default function ViewDBCTableModal(props: CreateDBCModalProps) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.parsedDBC) setLoading(false);
    else setLoading(true);
  }, [props.parsedDBC]);

  return (
    <Modal
      className="dark"
      size="fullscreen"
      onClose={props.onClose}
      onOpen={props.onOpen}
      open={props.open}
    >
      <Modal.Header>Combined CAN Message and Signals Table</Modal.Header>
      <Modal.Content>
        {loading ? (
          <LoadingAnimation
            loaderContainerHeight="calc(100%)"
            fontSize="1.5rem"
            loadingText="Loading..."
          />
        ) : (
          <Table textAlign="center" compact selectable size="small">
            <Table.Header>
              <Table.Row>
                <TableHeaderCell>CAN ID</TableHeaderCell>
                <TableHeaderCell>Message Name</TableHeaderCell>
                {/* emphasis given to Signal Name column */}
                <TableHeaderCell width={4}>Signal Name</TableHeaderCell>
                <TableHeaderCell>Start Bit</TableHeaderCell>
                <TableHeaderCell>Length (Bits)</TableHeaderCell>
                <TableHeaderCell>Scale</TableHeaderCell>
                <TableHeaderCell>Offset</TableHeaderCell>
                <TableHeaderCell>Byte Order</TableHeaderCell>
                <TableHeaderCell>Choices</TableHeaderCell>
                <TableHeaderCell>Is Signed</TableHeaderCell>
                <TableHeaderCell>Is Float</TableHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {props.parsedDBC &&
              Object.keys(props.parsedDBC).length !== 0 &&
              props.parsedDBC.results?.json?.messages.length !== 0 ? (
                props.parsedDBC.results.json.messages.map(
                  (dbcMessage, messageKey) => {
                    return dbcMessage.signals.length !== 0 ? (
                      dbcMessage.signals.map((signal) => {
                        return (
                          <Table.Row key={messageKey}>
                            <Table.Cell>{dbcMessage.can_id}</Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip text={dbcMessage.name} />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip text={signal.name} />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip text={signal.start.toString()} />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip
                                text={signal.length.toString()}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip text={signal.scale.toString()} />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip
                                text={signal.offset.toString()}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip text={signal.byte_order} />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip
                                text={
                                  signal.choices
                                    ? JSON.stringify(signal.choices)
                                    : `${signal.choices}`
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip
                                text={signal.is_signed.toString()}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip
                                text={signal.is_float.toString()}
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    ) : (
                      <Table.Row key={messageKey}>
                        <Table.Cell>{dbcMessage.can_id}</Table.Cell>
                        <Table.Cell>{dbcMessage.name}</Table.Cell>
                        {Array.from({ length: 8 }).map((_, idx) => (
                          <Table.Cell key={idx}>--</Table.Cell>
                        ))}
                      </Table.Row>
                    );
                  }
                )
              ) : (
                <Table.Row>
                  <Table.Cell colspan={10}>
                    <ErrorMessage message={"No data found!"} iconSize="large" />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={props.onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
