import React from "react";
import {
  Input,
  DropdownProps,
  Dropdown,
  Tab,
  TabPane,
} from "semantic-ui-react";
import { TimeRange } from "../Datetime/TimeRange";
import { breakpoints } from "../../../common/breakpoints";
import convert from "convert-units";
import styled from "styled-components";

import { PanelDef, PanelMetaData } from "./PanelDef";
import { LineChartPanelDef } from "./LineChart/PanelDef";
import { LastValuePanelDef, TimestampedValue } from "./LastValue/PanelDef";
import { LocateDevicesPanelDef } from "./LocateDevices/PanelDef";
import { TrackDevicesPanelDef } from "./TrackDevices/PanelDef";
import { AggregateValuePanelDef } from "./AggregateValue/PanelDef";
import { PieChartPanelDef } from "./PieChart/PanelDef";
import { TimeseriesTablePanelDef } from "./TimeseriesTable/PanelDef";
import { PivotTablePanelDef } from "./PivotTable/PanelDef";
import { HistogramPanelDef } from "./Histogram/PanelDef";
import { BarChartPanelDef } from "./BarChart/PanelDef";
import { HeatmapPanelDef } from "./Heatmap/PanelDef";
import { LogsPanelDef } from "./Logs/PanelDef";
import { StaticTextPanelDef } from "./StaticText/PanelDef";
import { GaugeChartPanelDef } from "./GaugeChart/PanelDef";
import { LedPanelPanelDef } from "./LedPanel/PanelDef";
import { DevicePulsePanelDef } from "./DevicePulse/PanelDef";
import { StateTimelinePanelDef } from "./StateTimeline/PanelDef";
import { AlertsPanelDef } from "./Alerts/PanelDef";
import { SessionsPanelDef } from "./Sessions/PanelDef";
import { DeviceStatusPanelDef } from "./DeviceStatus/PanelDef";
import { MicelioLocateDevicesPanelDef } from "./MicelioPanels/MicelioLocateDevices/PanelDef";
import { MicelioTrackDevicesPanelDef } from "./MicelioPanels/MicelioTrackDevices/PanelDef";
import { MicelioStatusTablePanelDef } from "./MicelioPanels/MicelioStatusTable/PanelDef";
import { MicelioStatusPanelDef } from "./MicelioPanels/MicelioStatus/PanelDef";
import { MicelioStatsPanelDef } from "./MicelioPanels/MicelioStatsPanel/PanelDef";
import { MicelioAlertsPanelDef } from "./MicelioPanels/MicelioAlertsPanel/PanelDef";
import { IframePanelDef } from "./Iframe/PanelDef";
import { StreamStatusPanelDef } from "./StreamStatus/PanelDef";

export const fleetPanelTypes = [
  "line_chart",
  "last_value",
  // "gauge_chart",
  // "state_timeline",
  "led_panel",
  "aggregate_value",
  "locate_devices",
  // "track_devices",
  // "pie_chart",
  "timeseries_table",
  // "pivot_table",
  // "histogram",
  // "heatmap",
  "logs",
  "static_text",
  "bar_chart",
  "device_pulse",
  // "device_status",
  "alerts",
  "sessions",
  "iframe_panel",
  "stream_status",
];

export const devicePanelTypes = [
  "line_chart",
  "last_value",
  "gauge_chart",
  "state_timeline",
  "led_panel",
  "aggregate_value",
  "locate_devices",
  "track_devices",
  // "pie_chart",
  "timeseries_table",
  // "pivot_table",
  "histogram",
  // "heatmap",
  "logs",
  "static_text",
  "bar_chart",
  "alerts",
  "sessions",
  // "device_status",
  "iframe_panel",
  "stream_status",
];

export const panelTypeToPanelDefs = {
  line_chart: LineChartPanelDef,
  last_value: LastValuePanelDef,
  gauge_chart: GaugeChartPanelDef,
  state_timeline: StateTimelinePanelDef,
  led_panel: LedPanelPanelDef,
  locate_devices: LocateDevicesPanelDef,
  track_devices: TrackDevicesPanelDef,
  aggregate_value: AggregateValuePanelDef,
  pie_chart: PieChartPanelDef,
  timeseries_table: TimeseriesTablePanelDef,
  pivot_table: PivotTablePanelDef,
  histogram: HistogramPanelDef,
  bar_chart: BarChartPanelDef,
  heatmap: HeatmapPanelDef,
  logs: LogsPanelDef,
  static_text: StaticTextPanelDef,
  device_pulse: DevicePulsePanelDef,
  alerts: AlertsPanelDef,
  sessions: SessionsPanelDef,
  device_status: DeviceStatusPanelDef,
  iframe_panel: IframePanelDef,
  stream_status: StreamStatusPanelDef,

  //Legacy names
  big_number: LastValuePanelDef,
  google_maps: LocateDevicesPanelDef,

  //custom widgets for micelio
  micelio_locate_devices: MicelioLocateDevicesPanelDef,
  micelio_track_devices: MicelioTrackDevicesPanelDef,
  micelio_status_table: MicelioStatusTablePanelDef,
  micelio_status: MicelioStatusPanelDef,
  micelio_stats_panel: MicelioStatsPanelDef,
  micelio_alerts_panel: MicelioAlertsPanelDef,
};

export const devicePanelTooltip = {
  line_chart:
    "Displays data points connected by straight lines, useful for tracking changes and trends over time. It's significant for monitoring variables that change continuously, such as temperature or speed.",
  last_value:
    "Shows the most recent value of a selected column in a stream. This is crucial for real-time monitoring, providing immediate insights into the latest data from sensors or devices.",
  gauge_chart:
    "Represents data in a circular arc format, often used to display performance metrics against set goals, like speedometers for bandwidth usage or progress towards energy consumption targets.",
  state_timeline:
    "Visualizes changes in state over time for any given entity, highlighting durations and transitions. It's significant for understanding the status history of a system or component.",
  led_panel:
    "Emulates an LED display to show concise data such as on/off or active/inactive states. It's significant for at-a-glance status indicators in an IoT environment.",
  aggregate_value:
    "Calculates and presents a summary statistic, like min, max, count, sum or average, of a particular dataset. This panel is key for gaining insights into collective trends and overall performance.",
  locate_devices:
    "Provides a geographical representation of device locations, often used for device tracking and management.",
  track_devices:
    "Similar to locate devices but with a focus on the movement and paths of devices over time. This panel is significant for monitoring mobility patterns and operational efficiency.",
  pie_chart:
    "Represents proportions of categories as slices of a pie, ideal for showing percentage distributions of a finite dataset, like the share of different device types in a fleet.",
  timeseries_table:
    "Displays data in a tabular format with timestamped entries, ideal for detailed chronological analysis of data points collected over time.",
  pivot_table:
    "Allows dynamic reorganization and summarization of large datasets, crucial for cross-referencing and comparing different data dimensions.",
  histogram:
    "Visualizes the distribution of a dataset by grouping data into bins, significant for identifying patterns or anomalies in frequency distributions.",
  heatmap:
    "Uses color coding to represent the magnitude of a metric across two dimensions, essential for spotting trends, deviations, and patterns in complex datasets.",
  logs: "Displays a record of events or changes, providing detailed textual data for troubleshooting, auditing, or analyzing the behavior of systems over time.",
  static_text:
    "Provides the ability to include explanatory text, annotations, or headings on a dashboard, which is significant for context.",
  bar_chart:
    "Uses rectangular bars to represent data values, excellent for comparing quantities across different categories or time intervals.",
  alerts:
    "Lists notifications or warnings based on specified criteria, critical for proactive monitoring and immediate response to issues.",
  sessions:
    "Displays information about active or historical user sessions, crucial for understanding user interactions and system access patterns.",
  device_status:
    "This panel provides a quick overview of the operational status of devices within the IoT ecosystem. It typically displays whether devices are online or offline, battery levels, connectivity strength, and any fault indicators.",
  device_pulse:
    "The Device Pulse Panel is a visualization tool that offers real-time insights into the heartbeat or activity of IoT devices. It shows the periodic signals or 'pulses' sent by devices to indicate they are active and functioning correctly.",
  iframe_panel:
    "Embeds external web content or applications within a dashboard, enabling integration with third-party services or custom applications.",
  stream_status:
    "Shows the current status of a stream, including whether any data is saved in the table and the time of the last data push. This panel is crucial for monitoring the real-time operational status of systems.",
};

export function getPanelDefForPanelType(type: string): PanelDef<any, any> {
  return panelTypeToPanelDefs[type];
}

export type PanelData<MetaDataType extends PanelMetaData, DataType> = {
  panelDef: PanelDef<MetaDataType, DataType>;
  meta: MetaDataType;
  data?: DataType;
  isDirty: boolean;
  loading: boolean;
  error?: boolean;
  errorMessage?: string;
};

export type FetchParams = {
  groupBys: string[];
  filterBys: { [key: string]: string[] };
  timeRange: TimeRange;
  fetchAll: boolean;
};

export const EditMetaRoot = styled.div`
  width: 100%;
`;

export const EditMetaRow = styled.div`
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const EditMetaRowDraggable = styled.div`
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: ${breakpoints.xs}px) {
    flex-wrap: nowrap;
  }
`;

export const EditMetaInput = styled(Input)`
  max-width: 350px;
  flex-grow: 1;
  margin-bottom: 10px;
  display: inline-block;
`;

EditMetaInput.defaultProps = {
  labelPosition: "left",
};

const EditMetaDropdownContainer = styled.div`
  max-width: 350px;
  width: 97.5%;
  flex-grow: 1;
  margin-bottom: 16px;
  display: inline-block;
  border-width: 1px;

  @media (max-width: ${breakpoints.xs}px) {
    width: 100%;
    max-width: unset;
  }
`;

export const StyledInputDiv = styled.div<{
  marginTop?: string;
  width: string;
  smInputWidth?: string;
}>`
  width: ${(props) => props.width};
  margin-top: ${(props) => props.marginTop ?? "0px"};

  @media (max-width: ${breakpoints.xs}px) {
    width: ${(props) => props.smInputWidth ?? "100%"};
  }
`;

export const StyledDashboardModalInput = styled(Input)`
  @media (max-width: ${breakpoints.xs}px) {
    width: 100% !important;

    & .ui.buttons {
      width: 100%;
    }

    & .ui.input {
      width: 100%;
      min-width: unset !important;
    }

    & .ui.selection.dropdown {
      width: 100%;
      min-width: unset !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .ui.multiple.search.dropdown > .text {
      top: unset !important;
    }

    .ui.multiple.dropdown .dropdown.icon {
      top: unset;
    }
  }
`;

const DropdownParent = styled.div`
  position: relative;
`;

const DropdownLabel = styled.div<{ labelBg?: string }>`
  position: absolute;
  top: -12px !important;
  left: 9px !important;
  line-height: 40px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  background: ${({ theme, labelBg }) =>
    labelBg ?? theme.colors["background-color"]} !important;
  transition: 300ms all;
  opacity: 0;
  z-index: -1;
`;

const StaticDropdownLabel = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  transition: 300ms all;
  line-height: 20px;
  font-size: 12px;
  top: -12px;
  background: ${({ theme }) => theme.colors["background-color"]} !important;
  padding: 0 6px;
  left: 9px;
`;

export const EditMetaDropdown = (props: DropdownProps) => (
  <EditMetaDropdownContainer>
    {props.staticLabel ? (
      <DropdownParent>
        <Dropdown {...props} style={{ borderWidth: "1px", width: "100%" }} />
        <StaticDropdownLabel>{props.placeholder}</StaticDropdownLabel>
      </DropdownParent>
    ) : (
      <Dropdown {...props} style={{ borderWidth: "1px", width: "100%" }} />
    )}
  </EditMetaDropdownContainer>
);

export const EditAnimatedMetaDropdown = (props: DropdownProps) => (
  <EditMetaDropdownContainer
    style={{
      ...(props.style ?? { marginBottom: props.style?.marginBottom ?? "16px" }),
    }}
  >
    <DropdownParent>
      <Dropdown
        {...props}
        style={{
          borderWidth: "1px",
          width: "100%",
          borderColor: props.error ? "red" : undefined,
          ...(props.style ?? {}),
        }}
        onFocus={() =>
          document
            .getElementById(props.elementid)
            ?.classList.add("triggerLabel")
        }
      />
      <DropdownLabel
        id={props.elementid}
        className={props.defaultValue !== undefined ? "triggerLabel" : ""}
        labelBg={props.labelBg}
      >
        {props.placeholder}
      </DropdownLabel>
      {/* {props.error && <span style={{ color: 'red' }}>Error message here</span>} */}
    </DropdownParent>
  </EditMetaDropdownContainer>
);

const InputLabel = styled.div`
  position: absolute;
  top: 2px;
  left: 15px;
  line-height: 40px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  background: ${({ theme }) => theme.colors["background-color"]} !important;
  transition: 300ms all;
  opacity: 0 !important;
  z-index: -1 !important;
`;

export const EditAnimatedMetaInput = (props) => {
  const errorStyle = props.error ? { borderColor: "red" } : {};

  return props.singleLine ? (
    <EditMetaInput
      className="add-panel-title-input"
      labelPosition="left"
      error={props.error}
      style={errorStyle}
      disabled={props.disabled}
    >
      <input
        autoFocus={props.autoFocus || false}
        ref={props.defaultRef}
        defaultValue={props.defaultValue}
        placeholder={props.label}
        onChange={props.onChangeEvent}
        type={props.type}
        value={props.value}
        style={props.style}
        disabled={props.disabled}
        min={props.min}
      />
      <InputLabel>{props.label}</InputLabel>
    </EditMetaInput>
  ) : (
    <EditMetaRow>
      <Input
        className="add-panel-title-input"
        labelPosition="left"
        error={props.error}
        style={errorStyle}
        disabled={props.disabled}
      >
        <input
          autoFocus={props.autoFocus || false}
          ref={props.defaultRef}
          defaultValue={props.defaultValue}
          placeholder={props.label}
          onChange={props.onChangeEvent}
          type={props.type}
          value={props.value}
          style={props.style}
          disabled={props.disabled}
          min={props.min}
        />
        <InputLabel>{props.label}</InputLabel>
      </Input>
    </EditMetaRow>
  );
};

export const StyledSemanticTabs = styled(Tab)`
  &&& .ui.attached.tabular.menu {
    display: flex !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    border-bottom: none !important;
    position: sticky;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors["panel-background"]};

    .active.item {
      background-color: ${({ theme }) =>
        theme.colors["styled_pane_tab-active-background"]} !important;
      border-color: ${({ theme }) =>
        theme.colors["styled_pane_tab-border-color"]} !important;
      color: ${({ theme }) => theme.colors["text"]} !important;
    }

    .item {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors["styled_pane_tab-border-color"]} !important;
    }
  }
`;

export const StyledTabPaneNoBorder = styled(TabPane)<{
  isalertspaneltablesticky?: boolean;
}>`
  ${({ isalertspaneltablesticky }) =>
    isalertspaneltablesticky &&
    `
      display: flex !important;
      position: absolute !important;
      top: 95px !important;
      width: 100% !important;
      left: 0 !important;
    `}

  &&.ui.attached.segment {
    border: none !important;
  }
`;

export const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 50px;

  .tableContentContainer {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 0px 20px 40px 20px;

    .ui.table thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      z-index: 2;
    }
  }
`;

export const DeleteIconInPanel = styled.button`
  height: 35px;
  width: 30px;
  background-color: ${({ theme }) =>
    theme.colors["delete-icon-in-panel-background-color"]};
  color: ${({ theme }) => theme.colors["delete-icon-in-panel-color"]};
  border: ${({ theme }) => theme.colors["delete-icon-in-panel-border"]};
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors["delete-icon-in-panel-background-hover-color"]};
    color: ${({ theme }) => theme.colors["delete-icon-in-panel-hover-color"]};
    border: ${({ theme }) => theme.colors["delete-icon-in-panel-hover-border"]};
  }
`;

export const ReorderIconInPanel = styled.span<{ isDragging: boolean }>`
  display: flex; // Use flexbox
  align-items: center; // Vertically align items in the center
  justify-content: center; // Horizontally align items in the center
  height: 35px;
  padding: 5px;
  width: 35px;
  color: ${({ theme }) => theme.colors["reorder-icon-panel-color"]};
  border-radius: 5px;
  &:hover {
    color: ${({ theme }) => theme.colors["reorder-icon-panel-hover-color"]};
  }

  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
`;

export const DraggableItem = styled.div`
  width: 100%;
`;

export const ThinDivider = styled.hr`
  border: none;
  border-top: ${(props) =>
    `1px solid ${props.theme.colors["container-secondary-border-color"]}`} !important;
  margin-top: 4px;
  margin-bottom: 16px;
  width: 100%;
`;

export const ThinHalfDivider = styled.hr`
  border: none;
  border-top: 1px solid #34373b;

  width: 50%;
`;

export const LabelHeading = styled.label`
  padding-right: 10px;
  font-weight: bold;
  margin-top: 5px;
  width: 60%;
`;

export const OverflowDiv = styled.div`
  .big-number-value {
    width: 100%;
    word-break: break-word;
  }

  .big-number-value span {
    width: 100%;
    padding: 10px;
    overflow-y: auto;
    display: inline-flex;
    justify-content: center;
  }
`;

export const DisplayValueDiv = styled.div`
  background: ${({ theme }) => theme.colors["background-color"]};
  padding-left: 20px;
  padding-top: 3px;
  margin-left: 15px;
  width: 60;
  height: 30;
  color: ${({ theme }) => theme.colors["foreground-color"]};
  font-size: 20px;
`;

export const ToggleLabel = styled.label`
  padding-right: 10px;
  width: 50%;
`;

export const StyledSketchPickerTrigger = styled.div`
  width: 96px;
  height: 32px;
  background-color: ${(props) => props.color};
  border: 4px solid
    ${({ theme }) => theme.colors["container-secondary-border-color"]};
  border-radius: 4px;
  cursor: pointer;
`;

// Numerical Streams fields types
export const NumericalStreamFieldsTypes = [
  "Float32",
  "Float64",
  "Int8",
  "Int16",
  "Int32",
  "Int64",
  "UInt8",
  "UInt16",
  "UInt32",
  "UInt64",
  "Nullable(Float32)",
  "Nullable(Float64)",
  "Nullable(Int8)",
  "Nullable(Int16)",
  "Nullable(Int32)",
  "Nullable(Int64)",
  "Nullable(UInt8)",
  "Nullable(UInt16)",
  "Nullable(UInt32)",
  "Nullable(UInt64)",
];

export const DateTimeStreamsFieldsTypes = [
  "Date",
  "DateTime",
  "DateTime64(3)",
  "DateTime64(6)",
  "Nullable(Date)",
  "Nullable(DateTime)",
  "Nullable(DateTime64(3))",
  "Nullable(DateTime64(6))",
];

// Functions to filter out numerical stream fields columns
export function filterNumericalTypeColumnOptions(
  columnOptions: Array<{ name: string; type: string }>
): Array<{ name: string; type: string }> {
  const numericalStreamFieldsTypesOptions = new Set(NumericalStreamFieldsTypes);
  if (columnOptions) {
    const columns = columnOptions.filter(
      (column: { name: string; type: string }) =>
        numericalStreamFieldsTypesOptions.has(column.type)
    );
    return columns;
  } else return [];
}

export function conditionalAggregators(type?: string) {
  const allowedAggregators = {
    String: ["min", "max", "minmax", "count"], // When Column type is String, only max and min aggregators are allowed
    "Nullable(String)": ["min", "max", "minmax", "count"], // When Column type is Nullable(String), only max and min aggregators are allowed
    default: ["min", "max", "minmax", "avg", "sum", "count"], // Other types of columns (Boolean, Date, Time, DateTime, DateTime64(3) and all Numerical type) can be treated as same
  };

  return allowedAggregators[type ?? "default"] || allowedAggregators.default;
}

export const ErrorText = styled.p`
  color: red;
  font-size: 12px;
`;

function formatNumberWithUnit(
  value: number,
  unit: string,
  enableRoundoff: boolean,
  roundOffPrecision: number = 3
): string {
  const roundedValue = enableRoundoff
    ? parseFloat(value.toFixed(roundOffPrecision)).toString()
    : value.toString();
  return `${roundedValue} ${unit}`;
}

function formatNumberAsHex(value: number): string {
  return `0x${value.toString(16).toUpperCase()}`;
}

function formatNumber(
  value: number,
  valueUnit: string | null,
  enableRoundoff: boolean,
  roundOffPrecision: number = 3,
  enableUnits: boolean,
  autoScaleUnits: boolean
): string {
  if (valueUnit && enableUnits) {
    if (["%", "m/s2", "ohm", "N"].includes(valueUnit) || !autoScaleUnits) {
      if (enableRoundoff) {
        return `${parseFloat(value.toFixed(roundOffPrecision)).toString()} ${valueUnit}`;
      } else {
        return `${value} ${valueUnit}`;
      }
    }
    const valueObj = convert(value).from(valueUnit).toBest();
    return formatNumberWithUnit(
      valueObj.val,
      valueObj.unit,
      enableRoundoff,
      roundOffPrecision
    );
  } else {
    return enableRoundoff
      ? parseFloat(value.toFixed(roundOffPrecision)).toString()
      : value.toString();
  }
}

export function formatValue(
  value: any,
  valueUnit: string | null = null,
  asHex: boolean = false,
  enableRoundoff: boolean = true,
  roundOffPrecision: number = 3,
  enableUnits: boolean = false,
  autoScaleUnits: boolean = false
): string {
  if (typeof value === "number") {
    return asHex
      ? formatNumberAsHex(value)
      : formatNumber(
          value,
          valueUnit,
          enableRoundoff,
          roundOffPrecision,
          enableUnits,
          autoScaleUnits
        );
  }

  if (typeof value === "boolean") {
    return value ? "true" : "false";
  }

  return value ?? "-";
}

export const EditPanelFormContainer = styled.div`
  width: 100%;
  height: 100%;

  .AxisRangeDiv {
    display: flex;
    margin-top: 20px;

    .AxisRangeInputs {
      width: 20%;
      justify-content: space-between;
    }
  }

  @media (max-width: ${breakpoints.xs}px) {
    .AxisRangeDiv {
      flex-direction: column;

      label {
        padding-bottom: 5px;
      }

      .AxisRangeInputs {
        width: 90%;
      }
    }
  }
`;

// Get Dashboard state from url params
export function getDashboardStateQueryParam() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const state = urlParams.getAll("state")?.map((s) => JSON.parse(s));
  return state;
}

// Convert all-data of last value in data format for fleet dashboard
export function convertDataFormatToLastValueData(data): TimestampedValue[] {
  let result: TimestampedValue[] = [];
  for (const key in data) {
    if (data.hasOwnProperty(key) && key !== "id") {
      result.push({
        id: data.id,
        value: data[key],
        column: key,
      });
    }
  }
  return result;
}

/**
 * Downloads a file using ReadableStream to handle large files efficiently
 * @param {Response} response - The response object from fetch or any other network request
 * @param {string} filename - The desired name for the downloaded file
 * @param {AbortSignal} signal - Optional AbortSignal to allow cancellation of the download
 */
export async function downloadFileWithStream(
  response: Response,
  filename: string
): Promise<void> {
  if (!response.body) {
    throw new Error("Response body is null or undefined");
  }

  const reader = response.body.getReader();

  const stream = new ReadableStream({
    start(controller) {
      function push() {
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          })
          .catch((err) => {
            console.error("Error reading stream", err);
            controller.error(err);
          });
      }
      push();
    },
    cancel() {
      console.log("Stream cancelled");
      reader.cancel();
    },
  });

  // Create a new Response with the stream and a Blob
  const responseStream = new Response(stream);
  const blob = await responseStream.blob();

  // Create a temporary URL and download the file
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}
