import React from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import { SessionAggregate } from "./PanelDef";
import { EditAnimatedMetaDropdown, EditAnimatedMetaInput } from "../util";
import { TableInfo } from "../../../../../BytebeamClient";
import { dropDownOptionsFromArray } from "../../../../../util";

type AggregateSelectorProps = {
  showRemoveIcon: boolean;
  showAddIcon: boolean;
  onAddRow: () => any;
  onRemoveRow: () => any;
  defaultValue?: SessionAggregate;
  tables: TableInfo;
  elementid: string;
  invalidAggregateNames: string[];
};

type AggregateSelectorState = {
  table: string;
  column: string;
  columnType: string;
  aggregator: string;
};

export class AggregateSelector extends React.Component<
  AggregateSelectorProps,
  AggregateSelectorState
> {
  nameRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    if (props.defaultValue) {
      this.state = props.defaultValue;
    } else {
      this.state = {
        table: "",
        column: "",
        columnType: "",
        aggregator: "",
      };
    }
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      column: "",
      columnType: "",
      aggregator: "",
    });
  }

  setColumn(_event, data) {
    const columnType = data.options.filter(
      (item) => item.value === data.value
    )[0].type;
    this.setState({
      column: data.value,
      columnType: columnType,
      aggregator: "",
    });
  }

  setAggregator(_event, data) {
    this.setState({
      aggregator: data.value,
    });
  }

  getAggregate() {
    const { table, column, aggregator } = this.state;
    return {
      name: this.nameRef.current?.value || "",
      table,
      column,
      aggregator,
    };
  }

  isFilled() {
    return (
      !!this.nameRef.current?.value &&
      !!this.state.table &&
      !!this.state.column &&
      !!this.state.aggregator
    );
  }

  render() {
    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
    }> = [];

    if (!!this.state.table) {
      try {
        columnTypeOptions = this.props.tables[this.state.table].map(
          (f: { name: string; type: string }) => {
            return {
              key: f.name,
              text: f.name,
              value: f.name,
              type: f.type,
            };
          }
        );
      } catch (e) {
        console.error(`${this.state.table} stream is not found in tables`);
        console.error(e);
      }
    }

    function conditionalAggregators(type?: string) {
      const allowedAggregators = {
        String: ["max", "min", "count", "first", "last"],
        "Nullable(String)": ["max", "min", "count", "first", "last"],
        default: [
          "min",
          "max",
          "avg",
          "sum",
          "count",
          "delta",
          "first",
          "last",
        ],
      };

      return (
        allowedAggregators[type ?? "default"] || allowedAggregators.default
      );
    }

    const aggregatorOptions = dropDownOptionsFromArray(
      conditionalAggregators(this.state.columnType)
    );

    return (
      <Table.Row
        style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
      >
        <Table.Cell
          style={{
            padding: "4px 11px 0px 11px",
            width: "50%",
            borderStyle: "none",
          }}
        >
          <EditAnimatedMetaInput
            defaultRef={this.nameRef}
            defaultValue={this.props.defaultValue?.name}
            label="Aggregate Name"
            error={this.props.invalidAggregateNames.find(
              (name) => this.nameRef.current?.value === name
            )}
          />
        </Table.Cell>

        <Table.Cell
          style={{
            padding: "4px 11px 0px 11px",
            width: "50%",
            borderStyle: "none",
          }}
        >
          <EditAnimatedMetaDropdown
            placeholder="Select Stream"
            text={this.state.table || "Select Stream"}
            search
            selection
            options={tableOptions}
            onChange={this.setTable.bind(this)}
            defaultvalue={this.state.table || ""}
            value={this.state.table || ""}
            elementid={`table${this.props.elementid}`}
          />
        </Table.Cell>

        <Table.Cell
          style={{
            padding: "4px 11px 0px 11px",
            width: "50%",
            borderStyle: "none",
          }}
        >
          <EditAnimatedMetaDropdown
            placeholder="Select Field"
            text={this.state.column || "Select Field"}
            search
            selection
            disabled={this.state.table === ""}
            options={columnTypeOptions}
            onChange={this.setColumn.bind(this)}
            defaultValue={this.state.column || ""}
            value={this.state.column || ""}
            elementid={`column${this.props.elementid}`}
          />
        </Table.Cell>

        <Table.Cell
          style={{
            padding: "4px 11px 0px 11px",
            width: "50%",
            borderStyle: "none",
          }}
        >
          <EditAnimatedMetaDropdown
            placeholder="Aggregator"
            text={this.state.aggregator || "Aggregator"}
            search
            selection
            disabled={this.state.column === ""}
            options={aggregatorOptions}
            onChange={this.setAggregator.bind(this)}
            defaultValue={this.state.aggregator || ""}
            value={this.state.aggregator || ""}
            elementid={`aggregate${this.props.elementid}`}
          />
        </Table.Cell>

        <Table.Cell
          style={{ padding: "0px 0px 11px 11px", borderStyle: "none" }}
        >
          <div>
            {this.props.showRemoveIcon ? (
              <Button secondary onClick={this.props.onRemoveRow} icon>
                <Icon name="minus" />
              </Button>
            ) : (
              ""
            )}
            {this.props.showAddIcon ? (
              <Button primary onClick={this.props.onAddRow} icon>
                <Icon name="add" />
              </Button>
            ) : (
              ""
            )}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
}
