import React, { useState, useEffect } from "react";
import { Icon, Button, Form, Modal } from "semantic-ui-react";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chaos";
import {
  editDeviceConfiguration,
  createDeviceConfiguration,
  fetchDeviceConfigurationDetails,
  DeviceConfigurationType,
} from "../../../../../BytebeamClient";
import { Mixpanel } from "../../../common/MixPanel";
import { DeviceConfigurationsOperationType } from "./DeviceConfigurations";
import GeofenceEditor, { LatLng } from "./GeofenceEditor";
import { useUser } from "../../../../../context/User.context";
import LoadingAnimation from "../../../../common/Loader";
import { beamtoast } from "../../../../common/CustomToast";

type CreateGeofenceConfigurationsModalProps = {
  type: string;
  changeType: (type: string) => void;
  name: string;
  isOpen: boolean;
  close: () => void;
  setConfig: (value: DeviceConfigurationType) => void;
  fillConfigsTable: () => Promise<void>;
  allExistingDeviceConfigurations: DeviceConfigurationType[];
  config: DeviceConfigurationType;
  triggerOpen: boolean;
  setTriggerOpen: (arg0: boolean) => void;
};

const TYPE_BASED_ACTION = {
  Create: "Create",
  Edit: "Update",
  View: "View",
};

function CreateGeofenceConfigurationsModal(
  props: CreateGeofenceConfigurationsModalProps
) {
  const {
    type,
    config,
    changeType,
    name,
    isOpen,
    setConfig,
    close,
    fillConfigsTable,
    allExistingDeviceConfigurations,
  } = props;

  const [loadingConfig, setLoadingConfig] = useState<boolean>(false);
  const [versionName, setVersionName] = useState<string>("");
  const [alertThreshold, setAlertThreshold] = useState<number>(20);
  const [, setExistingConfigVersions] = useState<Set<string>>(new Set());
  const [errors, setErrors] = useState<{
    versionName?: string;
    thresholdValue?: string;
  }>({
    versionName: "",
    thresholdValue: "",
  });

  const [disableAlertThreshold, setDisableAlertThreshold] =
    useState<boolean>(true);

  const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>(true);

  const [initialPath, setInitialPath] = useState<LatLng[]>([]);
  const [initialAlertThreshold, setInitialAlertThreshold] = useState<number>();

  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [path, setPath] = useState<LatLng[]>([]);
  const [isPolygonComplete, setIsPolygonComplete] = useState<boolean>(false);

  const [latitude, setLatitude] = useState<number>(12.927381); // defaults to bangalore
  const [longitude, setLongitude] = useState<number>(77.637729); // defaults to bangalore

  const [difference, setDifference] = useState<number>(0); // defaults to bangalore

  const callDeviceConfigApi = () => {
    const configJson = {
      type: "polygon",
      path,
      alert_interval: alertThreshold.toString(),
    };
    if (type === DeviceConfigurationsOperationType.Edit) {
      return editDeviceConfiguration(name, configJson);
    } else {
      return createDeviceConfiguration(
        versionName,
        configJson,
        "update_geofence"
      );
    }
  };

  const updateDeviceConfig = async () => {
    try {
      const newConfig = await callDeviceConfigApi();
      beamtoast.success(
        `${
          type === DeviceConfigurationsOperationType.Edit
            ? "Updated"
            : "Created"
        } Geofence Configuration ${newConfig.version_name}`
      );
      Mixpanel.track("Created Configuration", {
        Config: newConfig.version_name,
      });

      await fillConfigsTable();
      onCloseModal();
    } catch (e) {
      onCloseModal();
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  };

  // TODO: This Function can be removed if data is passed from parent component instead of fetching it again here from API call (fetchDeviceConfigurationDetails)
  async function fetchConfigDetails() {
    try {
      const config = await fetchDeviceConfigurationDetails(name);

      const { min, max, abs } = Math;

      const latitudes = config.config_json.path.map((coord) => coord.lat);
      const longitudes = config.config_json.path.map((coord) => coord.lng);

      const latDifference = max(...latitudes) - min(...latitudes);
      const lngDifference = max(...longitudes) - min(...longitudes);

      /**
       * this difference is calculated to get a rough idea about the area covered by the coordinates
       * yes, it is not fully accurate but does the job
       */

      const difference = abs(latDifference - lngDifference);

      const latitude =
        config.config_json.path.reduce((sum, coord) => sum + coord.lat, 0) /
        config.config_json.path?.length;
      const longitude =
        config.config_json.path.reduce((sum, coord) => sum + coord.lng, 0) /
        config.config_json.path?.length;

      setLatitude(latitude);
      setLongitude(longitude);
      setDifference(difference);
      setPath(config.config_json.path);
      setVersionName(config.version_name);
      setAlertThreshold(Number(config.config_json.alert_interval));

      setInitialPath(config.config_json.path);
      setInitialAlertThreshold(Number(config.config_json.alert_interval));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingConfig(false);
      setDisableAlertThreshold(false);
    }
  }

  function versionNameExists(allConfigs, versionName) {
    return allConfigs.some((item) => item.version_name === versionName);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let validity = 1;
    if (type === DeviceConfigurationsOperationType.Create) {
      if (versionName?.length === 0) {
        setErrors({
          versionName: "Please enter a Version Name",
        });
        validity = 0;
      }
    }
    if (
      type === DeviceConfigurationsOperationType.Create ||
      type === DeviceConfigurationsOperationType.Edit
    ) {
      if (alertThreshold < 1) {
        setErrors({
          thresholdValue: "Geofence alert threshold should be greater than 0",
        });
        validity = 0;
      } else if (!alertThreshold) {
        setErrors({
          thresholdValue: "Please enter valid geofence threshold",
        });
        validity = 0;
      } else if (!isPolygonComplete) {
        beamtoast.error("Please completely select a geofence on the map");
        validity = 0;
      }
    }
    if (type === DeviceConfigurationsOperationType.Create) {
      if (versionNameExists(allExistingDeviceConfigurations, versionName)) {
        setErrors({
          versionName: "This geofence config version already exists",
        });
        validity = 0;
      }
    }
    if (validity === 1) {
      // valid data, submit
      await updateDeviceConfig();
    }
  };

  // Reset state and close modal
  function onCloseModal() {
    // Reset state
    setVersionName("");
    setAlertThreshold(20);
    setIsPolygonComplete(false);
    setPath([]);
    setErrors({
      versionName: "",
      thresholdValue: "",
    });
    setLatitude(12.927381);
    setLongitude(77.637729);

    setConfig({
      action_type: "",
      tenant_id: "",
      is_deactivated: false,
      version_name: "",
      config_json: "",
    });

    // Close Modal
    close();
  }

  useEffect(() => {
    setDisableAlertThreshold(true);
    setDisableUpdateButton(true);
    const existingVersions: Set<string> = new Set();
    if (
      allExistingDeviceConfigurations &&
      type === DeviceConfigurationsOperationType.Create
    ) {
      // backup reset state if onCloseModal() is not triggered
      setVersionName("");
      setAlertThreshold(20);
      setIsPolygonComplete(false);
      setPath([]);
      setErrors({
        versionName: "",
        thresholdValue: "",
      });
      setLatitude(12.927381);
      setLongitude(77.637729);

      allExistingDeviceConfigurations.forEach((config) => {
        if (config.action_type === "update_geofence") {
          existingVersions.add(config.version_name);
        }
      });
      setExistingConfigVersions(existingVersions);
    }
    if (
      type === DeviceConfigurationsOperationType.Edit ||
      type === DeviceConfigurationsOperationType.View
    ) {
      setLoadingConfig(true);
      // TODO: Remove this function call after getting data from parent component, Also comment to remove warning can be removed then
      fetchConfigDetails();
    }
  }, [allExistingDeviceConfigurations, type, props.triggerOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      (path !== initialPath && isPolygonComplete) ||
      alertThreshold !== initialAlertThreshold
    ) {
      setDisableUpdateButton(false);
    } else setDisableUpdateButton(true);
  }, [
    path,
    alertThreshold,
    isPolygonComplete,
    loadingConfig,
    initialAlertThreshold,
    initialPath,
  ]);

  return (
    <Modal open={isOpen} onClose={onCloseModal} size="small" className="dark">
      <Modal.Header>{`${TYPE_BASED_ACTION[type]} Geofence Configuration`}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Version Name</label>
            <Form.Input
              disabled={
                type === DeviceConfigurationsOperationType.Edit ||
                type === DeviceConfigurationsOperationType.View
              }
              error={errors.versionName !== "" ? errors.versionName : null}
              placeholder={
                type === DeviceConfigurationsOperationType.Edit ||
                type === DeviceConfigurationsOperationType.View
                  ? name
                  : "production v1"
              }
              value={versionName}
              onChange={(e) => {
                setVersionName(e.target.value);
                setErrors({
                  versionName: "",
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Click to create geofence polygon</label>
            {!loadingConfig ? (
              <GeofenceEditor
                editable={type !== DeviceConfigurationsOperationType.View}
                latitude={latitude}
                longitude={longitude}
                difference={difference}
                path={path}
                onPolygonChanged={(path, isComplete) => {
                  setPath(path);
                  setIsPolygonComplete(isComplete);
                }}
                theme={theme}
              />
            ) : (
              <LoadingAnimation
                loaderContainerHeight="65vh"
                fontSize="1.5rem"
                loadingText="Loading Geofence Map"
              />
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="alert_threshold">Alert threshold (seconds)</label>
            <Form.Input
              id="alert_threshold"
              min="1"
              type="number"
              error={
                errors.thresholdValue !== "" ? errors.thresholdValue : null
              }
              disabled={
                type === DeviceConfigurationsOperationType.View
                  ? true
                  : type === DeviceConfigurationsOperationType.Create
                    ? false
                    : disableAlertThreshold
              }
              placeholder={20}
              value={alertThreshold ? alertThreshold : null}
              onChange={(e) => {
                setAlertThreshold(parseInt(e.target.value));
                setErrors({
                  versionName: "",
                });
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submitButton"
          primary
          disabled={
            config.is_deactivated
              ? config.is_deactivated
              : type === DeviceConfigurationsOperationType.View
                ? false
                : disableUpdateButton
          }
          onClick={async (e) => {
            if (type !== DeviceConfigurationsOperationType.View)
              await handleSubmit(e);
            else {
              setPath([]);
              changeType(DeviceConfigurationsOperationType.Edit);
            }
          }}
        >
          <Icon
            name={
              type === DeviceConfigurationsOperationType.View
                ? "edit"
                : "checkmark"
            }
          />{" "}
          {type === DeviceConfigurationsOperationType.View
            ? "Edit"
            : TYPE_BASED_ACTION[type]}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateGeofenceConfigurationsModal;
