import React, { useState, useEffect, useCallback, MouseEvent } from "react";
import {
  Accordion,
  Button,
  Grid,
  Input,
  MenuItem,
  Pagination,
  PaginationProps,
} from "semantic-ui-react";

import "./action-styles.scss";
// import DonutChart from "./DonutChart";
import NewActionModal from "./action-modals/NewActionModal";
import ActionLegend from "./ActionLegend";
import {
  ActionStatusResponse,
  ActionStatusType,
  devicesPerPageOptions,
  fetchAllActionStatus,
  fetchAllUsers,
} from "../../../BytebeamClient";
import { ErrorMessage } from "../../common/ErrorMessage";
import { StyledGridColumn } from "../DeviceManagement/Devices/Device";
import ActionCard from "./ActionCard";
import { ThinDivider } from "../Dashboards/Panel/util";
import { User, validateWholeNumber } from "../../../util";
import {
  SelectDevicesPerPage,
  StyledHeaderContainer,
  StyledBodyContainer,
} from "../DeviceManagement/Devices/Devices";
import styled from "styled-components";
import LoadingAnimation from "../../common/Loader";
import { StyledDevicePerPageWidget } from "../../common/commonStyledComps";
import { beamtoast } from "../../common/CustomToast";

const _ = require("lodash");

export const SearchPageInput = styled(Input)`
  height: 42px;
  margin-bottom: 0 !important;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  border-radius: 4px !important;
  border: ${({ theme }) => theme.colors["container-border"]} !important;
  box-shadow: ${({ theme }) =>
    theme.colors["pagination-box-shadow"]} !important;

  input {
    width: 150px;
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    background: ${({ theme }) =>
      theme.colors["secondary_tab-background"]} !important;
    border-radius: 4px !important;
  }

  input::placeholder {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color"]} !important;
  }

  input:focus::placeholder {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color-focus"]} !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

type ActionStatusProps = {
  user: User;
  currentTenant: string;
};

function ActionStatus(props: ActionStatusProps) {
  // const [allData, setAllData] = useState(Array<ActionStatusType>());
  const [filteredData, setFilteredData] = useState(Array<ActionStatusType>());
  const [filterStatus, setFilterStatus] = useState(false);

  const [filterName, setFilterName] = useState<string>("");

  const [totalActions, setTotalActions] = useState<number>(0);
  const [totalActionsForPagination, setTotalActionsForPagination] =
    useState<number>(0);

  const [actionsPageNumber, setActionsPageNumber] = useState(1);
  const [actionsPageLimit, setActionsPageLimit] = useState(0);

  const [initiatedList, setInitiatedList] = useState(Array<ActionStatusType>());
  const [, setInitiatedListCount] = useState(0);
  const [queuedList, setQueuedList] = useState(Array<ActionStatusType>());
  const [, setQueuedListCount] = useState(0);
  const [progressList, setProgressList] = useState(Array<ActionStatusType>());
  const [, setProgressListCount] = useState(0);
  const [failedList, setFailedList] = useState(Array<ActionStatusType>());
  const [, setFailedListCount] = useState(0);
  const [completedList, setCompletedList] = useState(Array<ActionStatusType>());
  const [, setCompletedListCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [triggerActionLoading, setTriggerActionLoading] = useState(false);
  // const [error, setError] = useState(false);

  const [inputPageNumber, setInputPageNumber] = useState(0);

  const [newActionModal, setNewActionModal] = useState(false);

  const [activeIndex, setActiveIndex] = useState(-1);

  const [users, setUsers] = useState<{ name: string; email: string }[]>([]);

  function sortActionBy(actionList) {
    let initiatedList: Array<ActionStatusType> = [],
      queuedList: Array<ActionStatusType> = [],
      progressList: Array<ActionStatusType> = [],
      failedList: Array<ActionStatusType> = [],
      completedList: Array<ActionStatusType> = [],
      finalList: Array<ActionStatusType> = [];

    actionList.forEach((action) => {
      finalList.push(action);
      if (action.statuses?.["Queued"] > 0) {
        queuedList.push(action);
        setQueuedListCount((count) => count + action.statuses?.["Queued"]);
      }
      if (action.statuses?.["Initiated"] > 0) {
        initiatedList.push(action);
        setInitiatedListCount(
          (count) => count + action.statuses?.["Initiated"]
        );
      }
      if (action.statuses?.["Completed"] > 0) {
        completedList.push(action);
        setCompletedListCount(
          (count) =>
            count +
            (action.statuses?.["Completed"]
              ? action.statuses?.["Completed"]
              : 0)
        );
      }
      if (action.statuses?.["Failed"] > 0) {
        failedList.push(action);
        setFailedListCount((count) => count + action.statuses?.["Failed"]);
      }
      // in_progress, Progress, Waiting for user, Waiting for Bluetooth, Downloaded, Downloading, Received, Extracting payload, Finalizing update, Installing, Installed

      const { Queued, Initiated, Completed, Failed, ...remaining } =
        action?.statuses;

      if (Object.keys(remaining)?.length !== 0) {
        progressList.push(action);
        Object.keys(remaining).forEach((status) =>
          setProgressListCount((prev) => prev + remaining[status])
        );
      }
    });

    setInitiatedList(initiatedList);
    setQueuedList(queuedList);
    setProgressList(progressList);
    setFailedList(failedList);
    setCompletedList(completedList);

    return _.orderBy(finalList, "created_at", "desc");
  }

  const handlePaginationInputChange = (event) => {
    const newValue = event.target.value;
    setInputPageNumber(newValue);
  };

  const handlePaginationInputKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(inputPageNumber.toString())) {
        onPaginationChange(event, {
          activePage:
            inputPageNumber && inputPageNumber > 0
              ? inputPageNumber >
                Math.ceil(totalActionsForPagination / actionsPageLimit)
                ? Math.ceil(totalActionsForPagination / actionsPageLimit)
                : inputPageNumber
              : 1,
          totalPages: Math.ceil(totalActionsForPagination / actionsPageLimit),
        });
        setInputPageNumber(0);
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  const onPaginationChange = (event: MouseEvent, data: PaginationProps) => {
    const activePage = data.activePage as number;
    setLoading(true);
    if (filterStatus) {
      switch (filterName) {
        case "Initiated Actions":
          setFilteredData(
            initiatedList.slice(
              (activePage - 1) * actionsPageLimit,
              activePage * actionsPageLimit
            )
          );
          break;

        case "Queued Actions":
          setFilteredData(
            queuedList.slice(
              (activePage - 1) * actionsPageLimit,
              activePage * actionsPageLimit
            )
          );
          break;

        case "In Progress Actions":
          setFilteredData(
            progressList.slice(
              (activePage - 1) * actionsPageLimit,
              activePage * actionsPageLimit
            )
          );
          break;

        case "Completed Actions":
          setFilteredData(
            completedList.slice(
              (activePage - 1) * actionsPageLimit,
              activePage * actionsPageLimit
            )
          );
          break;

        case "Failed Actions":
          setFilteredData(
            failedList.slice(
              (activePage - 1) * actionsPageLimit,
              activePage * actionsPageLimit
            )
          );
          break;

        default:
          setFilteredData(
            filteredData.slice(
              (activePage - 1) * actionsPageLimit,
              activePage * actionsPageLimit
            )
          );
          break;
      }
      setActionsPageNumber(activePage);
      setLoading(false);
    } else {
      setActionsPageNumber(activePage);
      fetchPaginatedActions(activePage, actionsPageLimit);
    }
  };

  const fetchPaginatedActions = async (
    pageNumber: number,
    pageLimit: number
  ) => {
    try {
      const res: ActionStatusResponse = await fetchAllActionStatus(
        pageNumber,
        pageLimit
      );
      let sortedActionList = [];
      sortedActionList = _.orderBy(res.actions, "created_at", "desc");
      setLoading(false);
      setFilteredData(sortedActionList); // filteredData will be the one used for displaying and filtering with
    } catch (e) {
      console.log(e);
      // setError(true);
    }
  };

  const fetchAllActions = async (count) => {
    setLoading(true);
    try {
      const res: ActionStatusResponse = await fetchAllActionStatus(1, 10000); // fetching all actions at once in order for filtering to work for now
      setTotalActions(res.count); // totalActions now contains a total count of all actions
      setTotalActionsForPagination(res.count);
      // let sortedActionList = [];
      // sortedActionList = sortActionBy(res.actions);
      // setAllData(sortedActionList); // allData now contains list of all existing actions
      sortActionBy(res.actions);
      fetchPaginatedActions(1, count);
    } catch (e) {
      console.log(e);
      // setError(true);
    }
  };

  const changeDevicesPerPage = useCallback(
    (e, data) => {
      try {
        setLoading(true);
        setActionsPageLimit(data.value);
        setActionsPageNumber(1);
        setInputPageNumber(0);
        setFilterStatus(false);
        setFilterName("");
        setActiveIndex(-1); // close the accordion
        window.localStorage.setItem("actionsPerPage", data.value);
        fetchAllActions(data.value);
      } catch (e) {
        beamtoast.error("Failed to change number of devices per page");
        console.error("Error in changeDeviceStatus: ", e);
      }
    },
    [actionsPageLimit, actionsPageNumber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    document.title = "Actions Summary | Bytebeam";
    const actionsCountPerPage = parseInt(
      window.localStorage.getItem("actionsPerPage") ?? "10"
    );
    setActionsPageLimit(actionsCountPerPage);
    fetchAllActions(actionsCountPerPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterData = (label: string) => {
    setLoading(true);
    setFilterStatus(true);
    setActionsPageNumber(1);
    setInputPageNumber(0);
    setFilterName(label);
    switch (label) {
      case "Initiated Actions":
        setFilteredData(initiatedList.slice(0, 1 * actionsPageLimit));
        setTotalActionsForPagination(initiatedList?.length);
        break;

      case "Queued Actions":
        setFilteredData(queuedList.slice(0, 1 * actionsPageLimit));
        setTotalActionsForPagination(queuedList?.length);
        break;

      case "In Progress Actions":
        setFilteredData(progressList.slice(0, 1 * actionsPageLimit));
        setTotalActionsForPagination(progressList?.length);
        break;

      case "Completed Actions":
        setFilteredData(completedList.slice(0, 1 * actionsPageLimit));
        setTotalActionsForPagination(completedList?.length);
        break;

      case "Failed Actions":
        setFilteredData(failedList.slice(0, 1 * actionsPageLimit));
        setTotalActionsForPagination(failedList?.length);
        break;

      default:
        setFilteredData(filteredData.slice(0, 1 * actionsPageLimit));

        break;
    }
    setLoading(false);
  };

  const fetchAllOfTheUsers = async () => {
    const res = await fetchAllUsers();
    let userArray: { name: string; email: string }[] = [];
    Object.values(res.result).forEach((user) =>
      userArray.push({ name: user.name, email: user.email })
    );
    setUsers(userArray);
  };

  useEffect(() => {
    fetchAllOfTheUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <LoadingAnimation
      loaderContainerHeight="65vh"
      fontSize="1.5rem"
      marginTopText="12px"
      loadingText="Loading Actions"
    />
  ) : triggerActionLoading ? (
    <LoadingAnimation
      loaderContainerHeight="65vh"
      fontSize="1.5rem"
      marginTopText="12px"
      loadingText="Triggering action"
    />
  ) : (
    <>
      <div>
        <p style={{ fontSize: "1.2rem", marginTop: "2rem" }}>Actions Summary</p>

        <div className="actions-summary-container">
          {filteredData?.length !== 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              {/* <DonutChart
                setTotalCount={
                  initiatedListCount +
                  queuedListCount +
                  progressListCount +
                  failedListCount +
                  completedListCount
                }
                setInitiatedListCount={initiatedListCount}
                setQueuedListCount={queuedListCount}
                setProgressListCount={progressListCount}
                setFailedListCount={failedListCount}
                setCompletedListCount={completedListCount}
                height={250}
                width={312.5}
                textInfo={true}
              /> */}
              <ActionLegend
                onClick={(label: string) => {
                  setActiveIndex(-1); // close the accordion when filters are triggered enabled
                  filterData(label);
                }}
                filterStatus={filterStatus}
                liStyles={{
                  paddingBottom: "0.5rem",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "1rem",
                alignItems: "self-start",
              }}
            >
              <ErrorMessage messageLess />
            </div>
          )}
          <Button
            primary
            icon
            disabled={props.user.role.permissions.allowedActions?.length === 0}
            title={
              props.user.role.permissions.allowedActions?.length > 0
                ? ""
                : "You do not have the necessary permissions"
            }
            onClick={() => {
              setNewActionModal(true);
              setFilterStatus(false); // need to confirm this
            }}
          >
            <p style={{ padding: "0 2rem", minWidth: "200px" }}>
              Create New Action
            </p>
          </Button>
        </div>

        <div
          style={{
            position: "relative",
          }}
        >
          <p style={{ fontSize: "1.2rem", marginBottom: "1rem" }}>
            Actions List {filterName !== "" ? `(${filterName})` : ""}
          </p>
          <p
            style={{
              fontSize: "1rem",
              cursor: "pointer",
              position: "absolute",
              right: 0,
              top: 0,
              paddingTop: "5px",
              textDecoration: "underline",
              visibility: filterStatus ? "visible" : "hidden",
            }}
            onClick={() => {
              setLoading(true);
              setActionsPageNumber(1);
              setInputPageNumber(0);
              setTotalActionsForPagination(totalActions);
              fetchPaginatedActions(1, actionsPageLimit);
              setFilterStatus(false);
              setFilterName("");
              setActiveIndex(-1); // close the accordion when filters are triggered enabled
            }}
          >
            Clear Filters
          </p>
        </div>

        <StyledHeaderContainer fluid>
          <Grid columns={6}>
            <Grid.Row>
              <StyledGridColumn style={{ fontWeight: 600 }}>
                #ID
              </StyledGridColumn>

              <StyledGridColumn style={{ fontWeight: 600 }}>
                Triggered At
              </StyledGridColumn>

              <StyledGridColumn style={{ fontWeight: 600 }}>
                Type
              </StyledGridColumn>

              <StyledGridColumn style={{ fontWeight: 600 }}>
                User
              </StyledGridColumn>

              <StyledGridColumn style={{ fontWeight: 600 }}>
                Progress
              </StyledGridColumn>

              <StyledGridColumn style={{ fontWeight: 600 }}>
                Details
              </StyledGridColumn>
            </Grid.Row>
          </Grid>
        </StyledHeaderContainer>

        <ThinDivider />

        {loading ? (
          <LoadingAnimation
            loaderContainerHeight="65vh"
            fontSize="1.5rem"
            marginTopText="12px"
            loadingText="Loading action statuses"
          />
        ) : filteredData?.length !== 0 ? (
          <StyledBodyContainer fluid>
            <Accordion>
              {filteredData.map((action, key) => (
                <ActionCard
                  key={key}
                  action={action}
                  activeIndex={activeIndex}
                  setActiveIndex={(index) => setActiveIndex(index)}
                  users={users}
                />
              ))}
            </Accordion>
          </StyledBodyContainer>
        ) : (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorMessage
              marginTop="30px"
              message={
                "No actions found! Please trigger an action to view its progress here."
              }
            />
          </div>
        )}
        {!loading && filteredData?.length !== 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: "16px",
            }}
          >
            <Pagination
              boundaryRange={0}
              ellipsisItem={null}
              siblingRange={2}
              // defaultActivePage={actionsPageNumber}
              activePage={actionsPageNumber}
              totalPages={Math.ceil(
                totalActionsForPagination / actionsPageLimit
              )}
              onPageChange={onPaginationChange}
            />

            <SearchPageInput
              icon="search"
              placeholder="Jump to page..."
              name="activePage"
              min={1}
              onChange={handlePaginationInputChange}
              onKeyDown={handlePaginationInputKeyDown}
              type="number"
              value={inputPageNumber ? inputPageNumber : ""}
            />

            <StyledDevicePerPageWidget>
              <MenuItem>Actions per page</MenuItem>
              <MenuItem style={{ padding: "0px" }}>
                <SelectDevicesPerPage
                  compact
                  selection
                  options={devicesPerPageOptions}
                  value={actionsPageLimit}
                  onChange={changeDevicesPerPage}
                />
              </MenuItem>
            </StyledDevicePerPageWidget>
          </div>
        )}
      </div>
      <NewActionModal
        isOpen={newActionModal}
        // fetchAllActions={() => fetchAllActions(actionsPageLimit)}
        onClose={() => setNewActionModal((state) => !state)}
        setLoading={(state) => setTriggerActionLoading(state)}
      />
    </>
  );
}

export default ActionStatus;
