import React from "react";

interface Phase {
  name: string;
  timestamp: number;
  info: {
    type: string;
    filter: { [key: string]: string[] };
    fraction: number;
    device_ids: number[];
  };
}

interface PhaseListProps {
  phaseData: Phase[];
}

const PhaseList: React.FC<PhaseListProps> = ({ phaseData }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {phaseData.map((phase, index) => (
        <div
          key={index}
          style={{
            border: "0.5px solid #4E5664",
            padding: "10px",
            marginBottom: "10px",
            marginRight: "10px",
            color: "#d1d1d3",
            lineHeight: "1",
          }}
        >
          <h3>{phase.name}</h3>
          <p>Scheduled: {new Date(phase.timestamp).toLocaleString("en-GB")}</p>
          {/* <p>Type: {phase.info.type}</p>
                    <p>Fraction: {phase.info.fraction}</p> */}
          <p>{phase.info.filter ? "Filter:" : null}</p>
          <ul>
            {phase.info.filter
              ? Object.keys(phase.info.filter).map((filterKey, filterIndex) => (
                  <li key={filterIndex}>
                    {filterKey}: {phase.info.filter[filterKey].join(", ")}
                  </li>
                ))
              : null}
          </ul>
          <p>
            {phase.info.device_ids && phase?.info?.device_ids?.length > 0
              ? "Device IDs:"
              : null}
          </p>
          <ul>
            {phase.info.device_ids && phase?.info?.device_ids?.length > 0
              ? phase.info.device_ids.map((deviceId, deviceIndex) => (
                  <li key={deviceIndex}>{deviceId}</li>
                ))
              : null}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default PhaseList;
