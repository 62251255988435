import React, { Component } from "react";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  Polygon,
  withScriptjs,
} from "react-google-maps";
import { GOOGLE_MAPS_API_KEY } from "../../../../../constants";
import { Button } from "semantic-ui-react";
import {
  darkTheme,
  lightTheme,
} from "../../../Dashboards/Panel/TrackDevices/MapStyle";

export interface LatLng {
  lat: number;
  lng: number;
}

interface GeofenceEditorState {
  polygonComplete: boolean;
}

interface GeofenceEditorProps {
  editable: boolean;
  path: LatLng[];
  onPolygonChanged: (path: LatLng[], isComplete: boolean) => void;
  theme: string;
  latitude: number;
  longitude: number;
  difference?: number;
}

class GeofenceEditor extends Component<
  GeofenceEditorProps,
  GeofenceEditorState
> {
  googleMapRef = React.createRef<GoogleMap>();

  constructor(props: GeofenceEditorProps) {
    super(props);

    this.state = {
      polygonComplete: false,
    };

    this.handleMapClick = this.handleMapClick.bind(this);
    this.handlePolygonComplete = this.handlePolygonComplete.bind(this);
    this.undoLastPoint = this.undoLastPoint.bind(this);
  }

  handleMapClick(event: google.maps.MapMouseEvent) {
    if (!this.state.polygonComplete && event.latLng) {
      const newPath = [
        ...this.props.path,
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
      ];
      this.props.onPolygonChanged(newPath, false);
    }
  }

  handlePolygonComplete() {
    this.setState({ polygonComplete: true });

    this.props.onPolygonChanged(this.props.path, true);
  }

  undoLastPoint() {
    if (this.props.path?.length > 0) {
      const newPath = this.props.path.slice(0, -1);
      this.setState({
        polygonComplete: false,
      });

      this.props.onPolygonChanged(newPath, false);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<GeofenceEditorProps>,
    prevState: Readonly<GeofenceEditorState>,
    snapshot?: any
  ): void {
    const map = this.googleMapRef.current;

    if (map && this.props.path?.length > 0 && !this.props.editable) {
      const bounds = new google.maps.LatLngBounds();

      this.props.path.forEach((point) => {
        bounds.extend(point);
      });

      map.fitBounds(bounds);
    }
  }

  render() {
    const mapTheme = this.props.theme === "light" ? lightTheme : darkTheme;

    let defaultOptions = {
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {},
      panControl: true,
      zoomControl: false,
      rotateControl: true,
      fullscreenControl: false,
      styles: mapTheme,
    };

    return (
      <div style={{ position: "relative" }}>
        {/* @ts-ignore */}
        <GoogleMap
          /**
           * cap zoom values from 5 to 15
           * using tailored m and c values through a linear equation to compute zoom using the difference value caluculated from coordinates
           * not accurate but does the job for most cases - better ux
           * fails to zoom enough for super small geofences
           * fallback to default zoom value which is 8
           */
          zoom={
            this.props.difference
              ? Math.max(
                  5,
                  Math.min(this.props.difference * -2.5129 + 10.9973, 15)
                )
              : 8
          }
          defaultCenter={{
            lat: this.props.latitude,
            lng: this.props.longitude,
          }}
          onClick={this.handleMapClick}
          options={defaultOptions}
          ref={this.googleMapRef}
        >
          <Polygon
            paths={this.props.path}
            editable={this.props.editable && !this.state.polygonComplete}
            options={{
              strokeColor: "#0000FF",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#0000FF",
              fillOpacity: 0.35,
            }}
            onMouseUp={this.handlePolygonComplete}
          />
        </GoogleMap>

        {this.props.editable ? (
          <Button
            id="undoLastPoint"
            primary
            style={{
              position: "absolute",
              bottom: "15px",
              right: "10px",
            }}
            onClick={this.undoLastPoint}
          >
            Undo Last Point
          </Button>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const GeofenceEditorWrapper = compose<GeofenceEditorProps, GeofenceEditorProps>(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" +
      GOOGLE_MAPS_API_KEY +
      "&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `calc(100vh - 384px)` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(GeofenceEditor);

export default GeofenceEditorWrapper;
