import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Icon,
  Modal,
} from "semantic-ui-react";
import {
  AlertNotificationRule,
  AlertRule,
  EmailNotificationParams,
  NotificationChannelParameters,
  NotificationChannelType,
  SlackNotificationParams,
  SmsNotificationParams,
  WebhookNotificationParams,
} from "../../../../util";
import { StyledInput, StyledLabel, Row } from "./CreateAlertRuleModal";
import styled from "styled-components";
import Toggle from "../../../common/Toggle";
import CreateSlackNotificationChannel from "./notificationChannels/CreateSlackNotificationChannel";
import CreateEmailNotificationChannel from "./notificationChannels/CreateEmailNotificationChannel";
import CreateWebhookNotificationChannel from "./notificationChannels/CreateWebhookNotificationChannel";
import CreateSmsNotificationChannel from "./notificationChannels/CreateSmsNotificationChannel";
import { NotificationRuleOperationType } from "./AlertRules";
import { beamtoast } from "../../../common/CustomToast";
import {
  fetchAlertNotificationRules,
  fetchTimezones,
  testAlertNotificationRule,
} from "../../../../BytebeamClient";
import useAsyncEffect from "../../common/useAsyncEffect";
import LoadingAnimation from "../../../common/Loader";

export interface SlackNotificationChannel {
  type: "slack";
  channelId: string;
  botToken: string;
}

export interface WebhookNotificationChannel {
  type: "webhook";
  url: string;
  headers: { [key: string]: string };
}

export interface EmailNotificationChannel {
  type: "email";
  emails: string[];
}

export interface SmsNotificationChannel {
  type: "sms";
  phoneNumbers: string[];
}

export type NotificationChannel =
  | SlackNotificationChannel
  | WebhookNotificationChannel
  | EmailNotificationChannel
  | SmsNotificationChannel;

export interface NotificationRule {
  alertRuleId: string;
  notificationChannel: NotificationChannel;
  interval_seconds: number;
  timezone: string;
}

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NotificationChannelButton = styled(Button)`
  width: 40%;
  margin-bottom: 15px !important;
  margin-left: 15px !important;
`;

type ChooseNotificationChannelProps = {
  readonly onSubmit: (channelType: NotificationChannelType) => void;
};

function ChooseNotificationChannel(props: ChooseNotificationChannelProps) {
  const { onSubmit } = props;
  return (
    <Row style={{ width: "100%", flexWrap: "wrap" }}>
      <NotificationChannelButton primary onClick={() => onSubmit("slack")}>
        <Icon name="slack" />
        Slack
      </NotificationChannelButton>
      <NotificationChannelButton primary onClick={() => onSubmit("email")}>
        <Icon name="mail" />
        Email
      </NotificationChannelButton>
      <NotificationChannelButton primary onClick={() => onSubmit("webhook")}>
        <Icon name="external" />
        Webhook
      </NotificationChannelButton>
      <NotificationChannelButton primary onClick={() => onSubmit("sms")}>
        <Icon name="phone" />
        SMS
      </NotificationChannelButton>
    </Row>
  );
}

interface CreateNotificationRuleModalProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly alertNotificationRule?: AlertNotificationRule;
  readonly title: string;
  readonly onSubmit: (notificationRule: AlertNotificationRule) => void;
  readonly alertRules: AlertRule[];
  readonly notificationModalStep: number;
  readonly operationType: "create" | "update";
}

function toNotificationRule(anr: AlertNotificationRule): NotificationRule {
  if (anr.channel_type === "webhook") {
    const channelParameters =
      anr.channel_parameters as WebhookNotificationParams;

    return {
      alertRuleId: anr.alert_rule_id,
      interval_seconds: anr.interval_seconds,
      notificationChannel: {
        type: "webhook",
        url: channelParameters.url,
        headers: channelParameters.headers,
      },
      timezone: anr.timezone,
    };
  }

  if (anr.channel_type === "sms") {
    const channelParameters = anr.channel_parameters as SmsNotificationParams;

    return {
      alertRuleId: anr.alert_rule_id,
      interval_seconds: anr.interval_seconds,
      notificationChannel: {
        type: "sms",
        phoneNumbers: channelParameters.phone_numbers,
      },
      timezone: anr.timezone,
    };
  }

  if (anr.channel_type === "email") {
    const channelParameters = anr.channel_parameters as EmailNotificationParams;

    return {
      alertRuleId: anr.alert_rule_id,
      interval_seconds: anr.interval_seconds,
      notificationChannel: {
        type: "email",
        emails: channelParameters.emails,
      },
      timezone: anr.timezone,
    };
  }

  if (anr.channel_type === "slack") {
    const channelParameters = anr.channel_parameters as SlackNotificationParams;

    return {
      alertRuleId: anr.alert_rule_id,
      interval_seconds: anr.interval_seconds,
      notificationChannel: {
        type: "slack",
        botToken: channelParameters.bot_token,
        channelId: channelParameters.slack_channel,
      },
      timezone: anr.timezone,
    };
  }

  throw new Error("Unknown channel type");
}

export default function CreateNotificationRuleModal(
  props: CreateNotificationRuleModalProps
) {
  const {
    open,
    onOpen,
    onClose,
    title,
    onSubmit,
    alertRules,
    alertNotificationRule,
    operationType,
  } = props;

  const notification = alertNotificationRule
    ? toNotificationRule(alertNotificationRule)
    : undefined;

  const [notificationRule, setNotificationRule] = useState<
    NotificationRule | undefined
  >(notification);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loadingTestNotification, setLoadingTestNotification] = useState(false);

  const [timezones, setTimezones] = useState<string[]>([]);

  const defaultTemplate = `
  {% for alert in alerts -%}
      {{ alert.name }} triggered at {{ alert.start_time }} on device {{ alert.device.id -}}
      {% if not loop.last %}
  
      {% endif %}
  {% endfor %}`;

  const [notificationRuleTemplate, setNotificationRuleTemplate] = useState<
    string | undefined
  >(defaultTemplate);
  const [notificationTemplateLoading, setNotificationTemplateLoading] =
    useState(false);
  let fetchNotificationRuleAbortController = new AbortController();

  const setIsValid = (isValid: boolean) => {
    setButtonDisabled(!isValid);
  };

  const createNotificationChannel = (channelType: NotificationChannelType) => {
    if (channelType === "slack") {
      const rule: NotificationRule = {
        alertRuleId: "",
        notificationChannel: {
          type: "slack",
          channelId: "",
          botToken: "",
        },
        interval_seconds: 60,
        timezone: "UTC",
      };

      setNotificationRule(rule);
    } else if (channelType === "email") {
      const rule: NotificationRule = {
        alertRuleId: "",
        notificationChannel: {
          type: "email",
          emails: [],
        },
        interval_seconds: 60,
        timezone: "UTC",
      };

      setNotificationRule(rule);
    } else if (channelType === "webhook") {
      const rule: NotificationRule = {
        alertRuleId: "",
        notificationChannel: {
          type: "webhook",
          url: "",
          headers: { "": "" },
        },
        interval_seconds: 60,
        timezone: "UTC",
      };

      setNotificationRule(rule);
    } else if (channelType === "sms") {
      const rule: NotificationRule = {
        alertRuleId: "",
        notificationChannel: {
          type: "sms",
          phoneNumbers: [],
        },
        interval_seconds: 60,
        timezone: "UTC",
      };

      setNotificationRule(rule);
    }
  };

  const setNotificationChannel = (channel: NotificationChannel) => {
    if (notificationRule) {
      setNotificationRule({
        ...notificationRule,
        notificationChannel: channel,
      });
    }
  };

  const renderNotificationChannel = () => {
    if (!notificationRule) {
      return <ChooseNotificationChannel onSubmit={createNotificationChannel} />;
    } else if (notificationRule.notificationChannel.type === "email") {
      return (
        <CreateEmailNotificationChannel
          channel={notificationRule.notificationChannel}
          setChannel={setNotificationChannel}
          setIsValid={setIsValid}
          notificationRule={notificationRule}
        />
      );
    } else if (notificationRule.notificationChannel.type === "slack") {
      return (
        <CreateSlackNotificationChannel
          channel={notificationRule.notificationChannel}
          setChannel={setNotificationChannel}
          setIsValid={setIsValid}
          notificationRule={notificationRule}
        />
      );
    } else if (notificationRule.notificationChannel.type === "webhook") {
      return (
        <CreateWebhookNotificationChannel
          channel={notificationRule.notificationChannel}
          setChannel={setNotificationChannel}
          setIsValid={setIsValid}
          notificationRule={notificationRule}
        />
      );
    } else if (notificationRule.notificationChannel.type === "sms") {
      return (
        <CreateSmsNotificationChannel
          channel={notificationRule.notificationChannel}
          setChannel={setNotificationChannel}
          setIsValid={setIsValid}
          notificationRule={notificationRule}
        />
      );
    }
  };

  const renderAlertId = () => {
    const alertRuleIdOptions: DropdownItemProps[] = alertRules.map((rule) => {
      return {
        key: rule.id,
        value: rule.id,
        text: rule.name,
      };
    });

    return (
      <StyledInput labelPosition="left">
        <StyledLabel>Alert Rule</StyledLabel>
        <Dropdown
          fluid
          selection
          search
          placeholder="Alert Rule"
          options={alertRuleIdOptions}
          value={notificationRule?.alertRuleId}
          onChange={(_e, d) => {
            if (notificationRule) {
              setNotificationRule({
                ...notificationRule,
                alertRuleId: d.value as string,
              });
            }
          }}
          disabled={operationType === NotificationRuleOperationType.Update}
          style={{
            border: "none",
          }}
        />
      </StyledInput>
    );
  };

  const renderAlertTimezone = () => {
    const timezoneOptions: DropdownItemProps[] = timezones.length
      ? timezones.map((timezone, key) => {
          return {
            key,
            value: timezone,
            text: timezone,
          };
        })
      : [];

    return (
      <StyledInput labelPosition="left">
        <StyledLabel>Timezone</StyledLabel>
        <Dropdown
          fluid
          selection
          search
          loading={timezones.length === 0}
          placeholder="Timezones"
          options={timezoneOptions}
          value={notificationRule?.timezone ?? "UTC"}
          onChange={(_e, d) => {
            if (notificationRule) {
              setNotificationRule({
                ...notificationRule,
                timezone: d.value as string,
              });
            }
          }}
          style={{
            border: "none",
          }}
        />
      </StyledInput>
    );
  };

  const renderInterval = () => {
    return (
      <StyledInput labelPosition="left">
        <StyledLabel>Interval (seconds)</StyledLabel>

        <input
          title="Interval (seconds)"
          type="number"
          value={notificationRule?.interval_seconds}
          onChange={(e) => {
            if (notificationRule) {
              setNotificationRule({
                ...notificationRule,
                interval_seconds: parseInt(e.target.value),
              });
            }
          }}
        />
      </StyledInput>
    );
  };

  const renderTemplate = () => {
    return (
      <Column>
        <Row style={{ marginBottom: "15px" }}>
          <label style={{ marginRight: "10px" }}>Advanced</label>
          <Toggle
            id="advancedToggle"
            checked={showAdvanced}
            onChange={() => setShowAdvanced(!showAdvanced)}
            style={{ top: "2px", marginRight: "10px" }}
          />
        </Row>

        {showAdvanced && (
          <>
            <label> Alert Template </label>
            <>
              {notificationTemplateLoading ? (
                <LoadingAnimation
                  loadingText="Loading notification rule template..."
                  loaderSize="30px"
                  fontSize="14px"
                  marginTopText="5px"
                  loaderContainerHeight="100px"
                  loaderContainerMargin="10px 0px 10px 0px"
                />
              ) : (
                <textarea
                  title="Alert Template"
                  value={notificationRuleTemplate}
                  onChange={(e) => {
                    setNotificationRuleTemplate(e.target.value);
                  }}
                  rows={10}
                  style={{ width: "100%", marginBottom: "15px" }}
                />
              )}
            </>
          </>
        )}
      </Column>
    );
  };

  const renderTestButton = () => {
    return (
      <Button
        primary
        disabled={buttonDisabled}
        loading={loadingTestNotification}
        onClick={handleTestNotificationRule}
      >
        Test Notification Rule
      </Button>
    );
  };

  const getModalSize = () => {
    if (notificationRule) {
      return "tiny";
    } else {
      return "mini";
    }
  };

  const createNotificationRule = () => {
    if (notificationRule) {
      let channelParameters: NotificationChannelParameters;

      if (notificationRule.notificationChannel.type === "slack") {
        channelParameters = {
          slack_channel: notificationRule.notificationChannel.channelId,
          bot_token: notificationRule.notificationChannel.botToken,
        };
      } else if (notificationRule.notificationChannel.type === "email") {
        channelParameters = {
          emails: notificationRule.notificationChannel.emails.filter(
            (e) => e !== ""
          ),
        };
      } else if (notificationRule.notificationChannel.type === "webhook") {
        channelParameters = {
          url: notificationRule.notificationChannel.url,
          headers: notificationRule.notificationChannel.headers,
        };
      } else if (notificationRule.notificationChannel.type === "sms") {
        channelParameters = {
          phone_numbers:
            notificationRule.notificationChannel.phoneNumbers.filter(
              (e) => e !== ""
            ),
        };
      } else {
        console.log("Unknown notification channel type");
        return;
      }

      const alertNotificationRule: AlertNotificationRule = {
        id: props.alertNotificationRule?.id,
        alert_rule_id: notificationRule.alertRuleId,
        interval_seconds: notificationRule.interval_seconds,
        channel_type: notificationRule.notificationChannel.type,
        channel_parameters: channelParameters,
        timezone: notificationRule.timezone,
        notification_template: notificationRuleTemplate,
      };

      return alertNotificationRule;
    }
  };

  const handleSubmit = () => {
    if (notificationRule) {
      if (!notificationRuleTemplate) {
        beamtoast.error("Notification template cannot be empty");
        return;
      }
      if (!notificationRuleTemplate) {
        beamtoast.error("Notification template cannot be empty");
        return;
      }
      const alertNotificationRule =
        createNotificationRule() as AlertNotificationRule;

      onSubmit(alertNotificationRule);
      handleModalClose();
    }
  };

  const handleTestNotificationRule = async () => {
    if (notificationRule) {
      const alertNotificationRule =
        createNotificationRule() as AlertNotificationRule;
      setLoadingTestNotification(true);
      try {
        await testAlertNotificationRule(alertNotificationRule);
        beamtoast.success(`Sent test notification`);
      } catch (e) {
        console.error("Error testing alert notification rule:", e);
        beamtoast.error(`Failed to send test notification`);
      } finally {
        setLoadingTestNotification(false);
      }
    }
  };

  const handleModalClose = () => {
    setNotificationRule(undefined);
    setButtonDisabled(true);
    setShowAdvanced(false);
    onClose();
  };

  const fetchAllTimezones = async () => {
    const res = await fetchTimezones();
    setTimezones(res);
  };

  // Fetch notification rule and set the template, if failed set the value to default template.
  const fetchAlertNotificationRuleTemplate = async () => {
    if (alertNotificationRule) {
      try {
        fetchNotificationRuleAbortController.abort();
        fetchNotificationRuleAbortController = new AbortController();
        setNotificationTemplateLoading(true);
        const res = await fetchAlertNotificationRules(
          alertNotificationRule,
          fetchNotificationRuleAbortController.signal
        );
        setNotificationRuleTemplate(res?.notification_template);
      } catch (e) {
        console.error("Error fetching alert notification rule template:", e);
      } finally {
        setNotificationTemplateLoading(false);
      }
    } else {
      setNotificationRuleTemplate(defaultTemplate);
    }
  };

  useAsyncEffect(fetchAlertNotificationRuleTemplate, [alertNotificationRule]);
  // Fetch notification rule and set the template, if failed set the value to default template.
  useAsyncEffect(fetchAllTimezones, []);

  useEffect(() => {
    const notification = alertNotificationRule
      ? toNotificationRule(alertNotificationRule)
      : undefined;
    setNotificationRule(notification);

    // Abort controller cleanup, aborting fetch requests when component unmounts.
    return () => {
      fetchNotificationRuleAbortController.abort();
    };
  }, [alertNotificationRule]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="dark"
      onClose={() => handleModalClose()}
      onOpen={onOpen}
      open={open}
      size={getModalSize()}
    >
      <Modal.Header>{notificationRule ? title : "Choose Channel"}</Modal.Header>
      <Modal.Content>
        {notificationRule ? renderAlertId() : <></>}

        <div>{renderNotificationChannel()}</div>

        {notificationRule ? (
          <>
            {renderInterval()}
            {renderAlertTimezone()}
            {notificationRule.notificationChannel.type === "slack" && (
              <p>
                <b>Note: </b>Please ensure that the bot is added to the channel
                for notifications to work correctly.
              </p>
            )}
            {renderTemplate()}
            {renderTestButton()}
          </>
        ) : (
          <></>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleModalClose}>
          Cancel
        </Button>

        {notificationRule ? (
          <Button
            type="submit"
            primary
            disabled={
              buttonDisabled ||
              (notificationRule.interval_seconds
                ? notificationRule.interval_seconds <= 0
                : true)
            }
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : (
          <></>
        )}
      </Modal.Actions>
    </Modal>
  );
}
