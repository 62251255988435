import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { ActionLog, fetchActionLogs } from "../../../../BytebeamClient";

const LogsContainer = styled.div`
  height: 320px;
  overflow-y: scroll;
  padding: 16px;
  background-color: black;
  color: #ffffff !important;
  border-radius: 5px;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080 !important;
  }
`;

interface ActionLogModalProps {
  actionId: string;
  deviceId: string;
  actionStatus: string;
  trigger: React.ReactNode;
}

const ActionLogModal: React.FC<ActionLogModalProps> = ({
  actionId,
  deviceId,
  actionStatus,
  trigger,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [logs, setLogs] = useState<ActionLog[]>([]);
  const [lastTimestamp, setLastTimestamp] = useState<Date>(new Date(0));
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId;

    const fetchLogs = async (controller: AbortController) => {
      if (!isModalOpen) {
        return;
      }

      try {
        const data: ActionLog[] = await fetchActionLogs(
          actionId,
          deviceId,
          lastTimestamp,
          500,
          controller.signal
        );
        setLoading(false);

        if (data?.length > 0) {
          setLogs((prevLogs) => [...prevLogs, ...data].map(log => {
            const logs = typeof log.logs  === "string" ? [] : log.logs;
            return { ...log, logs };
          }));

          timeoutId = setTimeout(() => {
            // This will trigger the useEffect again
            setLastTimestamp(new Date(data?.[data?.length - 1]?.timestamp));
          }, 1000);
        } else {
          timeoutId = setTimeout(fetchLogs, 1000);
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    const controller = new AbortController();
    fetchLogs(controller);

    return () => {
      controller.abort();
      clearTimeout(timeoutId);
    };
  }, [lastTimestamp, isModalOpen, actionId, deviceId]);

  const renderLogs = () => {
    if (loading) {
      return <div>Loading logs...</div>;
    }

    if (actionStatus === "Queued") {
      return <div> Action is queued, waiting for device to connect...</div>;
    }

    if (actionStatus === "Initiated") {
      return <div> Action is initiated, waiting for device to connect...</div>;
    }

    if (!logs?.length) {
      return <div>No logs available</div>;
    }

    return logs?.map((log) => {
      let color: string;

      switch (log?.status) {
        case "Completed":
          color = "green";
          break;
        case "Queued":
        case "Initiated":
          color = "blue";
          break;
        case "Failed":
          color = "red";
          break;
        default:
          color = "white";
          break;
      }

      return (
        <div key={log?.timestamp} style={{ color: color }}>
          {`${new Date(log?.timestamp)?.toLocaleString()} ${log?.status} ${
            log?.progress !== 100 ? `${log?.progress}%` : ""
          }  ${log?.logs?.join(" | ")}`}
        </div>
      );
    });
  };

  const closeModal = () => {
    setLogs([]);
    setLoading(true);
    setLastTimestamp(new Date(0));
    setIsModalOpen(false);
  };

  return (
    <Modal
      className="dark"
      trigger={trigger}
      open={isModalOpen}
      onClose={() => closeModal()}
      size="small"
      onOpen={() => setIsModalOpen(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <Modal.Header>
        Progress logs for action {actionId} and device {deviceId}
      </Modal.Header>
      <Modal.Content>
        <LogsContainer>{renderLogs()}</LogsContainer>
      </Modal.Content>

      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ActionLogModal;
