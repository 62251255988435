import React, { useEffect, useMemo, useState } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import { EditAnimatedMetaDropdown } from "../../../Dashboards/Panel/util";
import {
  addOrUpdateFirmwareDependency,
  FirmwareType,
  FirmwareVersionDependency,
} from "../../../../../BytebeamClient";
import { beamtoast } from "../../../../common/CustomToast";
import { isEqual } from "lodash";

export type DependencyType = FirmwareVersionDependency & {
  id: number;
};

type ComponentWiseFirmwareRowProps = {
  readonly dependentComponentWithVersion: DependencyType;
  readonly dependency: DependencyType;
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
  readonly loadingComponentList: boolean;
  readonly dependencies: DependencyType[];
  readonly setDependencies: (dependencies: DependencyType[]) => void;
};

export function ComponentWiseFirmwareRow(props: ComponentWiseFirmwareRowProps) {
  const {
    dependentComponentWithVersion,
    dependency,
    loadingComponentList,
    componentsList,
    firmwareVersions,
    dependencies,
    setDependencies,
  } = props;

  const [firmwareVersionOptions, setFirmwareVersionOptions] = useState<
    { key: string; value: string; text: string }[]
  >([]);

  const componentOptions = useMemo(() => {
    const selectedComponents = [
      dependentComponentWithVersion,
      ...dependencies,
    ].map((fw) => fw.component_name);
    const componentsWithFirmware = componentsList.filter((component) =>
      firmwareVersions.some(
        (fw) => fw.device_component_name === component && !fw.is_deactivated
      )
    );
    const componentsWithoutFirmware = componentsList.filter(
      (component) => !componentsWithFirmware.includes(component)
    );

    return [
      ...componentsWithFirmware.map((component) => ({
        key: component,
        value: component,
        text: component,
      })),
      ...componentsWithoutFirmware.map((component) => ({
        key: component,
        value: component,
        text: component,
        disabled: true,
        style: { cursor: "not-allowed" },
        title: "This component does not have a firmware version",
      })),
    ].filter(
      (component) =>
        !selectedComponents.includes(component.value) ||
        component.value === dependency.component_name
    );
  }, [
    dependentComponentWithVersion,
    componentsList,
    firmwareVersions,
    dependencies,
    dependency.component_name,
  ]);

  function handleSelectComponent(e, data) {
    e.preventDefault();

    const newDependencies = [...dependencies];
    newDependencies.map((newDependency) => {
      if (newDependency.id === dependency.id) {
        newDependency.component_name = data.value;
        newDependency.min_version = "";
      }

      return newDependency;
    });
    setDependencies(newDependencies);
  }

  function handleSelectedVersions(version: string) {
    const newDependencies = [...dependencies];
    newDependencies.map((newDependency) => {
      if (newDependency.id === dependency.id) {
        newDependency.min_version = version;
      }

      return newDependency;
    });
    setDependencies(newDependencies);
  }

  function handleAdd() {
    setDependencies([
      ...dependencies,
      {
        id: dependencies.at(-1)!.id + 1,
        component_name: "",
        min_version: "",
      },
    ]);
  }

  function handleDelete() {
    // reset dependency list if removing the only dependency
    if (dependencies.length === 1)
      setDependencies([{ id: 1, component_name: "", min_version: "" }]);
    else
      setDependencies(dependencies.filter((dep) => dep.id !== dependency.id));
  }

  useEffect(() => {
    const activeFirmwareVersions = firmwareVersions.filter(
      (fw) =>
        !fw.is_deactivated &&
        fw.device_component_name === dependency.component_name
    );
    const deactivatedFirmwareVersions = firmwareVersions.filter(
      (fw) =>
        fw.is_deactivated &&
        fw.device_component_name === dependency.component_name
    );

    setFirmwareVersionOptions([
      ...activeFirmwareVersions.map((version) => ({
        key: version.version_number,
        value: version.version_number,
        text: version.version_number,
      })),
      ...deactivatedFirmwareVersions.map((version) => ({
        key: version.version_number,
        value: version.version_number,
        text: version.version_number,
        disabled: true,
        style: { cursor: "not-allowed" },
        title: "This firmware version is deactivated",
      })),
    ]);
  }, [dependency.component_name]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Table.Row>
      <Table.Cell width={7}>
        <EditAnimatedMetaDropdown
          placeholder="Select Component"
          search
          selection
          options={componentOptions}
          onChange={handleSelectComponent}
          value={dependency.component_name}
          loading={loadingComponentList}
          disabled={loadingComponentList}
          elementid={`select_component`}
          style={{
            marginBottom: "4px",
            marginTop: "4px",
          }}
        />
      </Table.Cell>

      <Table.Cell width={7}>
        <EditAnimatedMetaDropdown
          placeholder={"Select Firmware Version"}
          fluid
          search
          selection
          disabled={dependency.component_name === ""}
          options={firmwareVersionOptions}
          onChange={(e, data) => {
            e.preventDefault();
            handleSelectedVersions(data.value as string);
          }}
          value={dependency.min_version}
          elementid={`select_version`}
          style={{
            marginBottom: "4px",
            marginTop: "4px",
          }}
        />
      </Table.Cell>
      <Table.Cell width={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
            gap: "8px",
          }}
        >
          {dependencies?.length > 0 && (
            <Button
              onClick={handleDelete}
              icon="minus"
              secondary
              disabled={
                dependency.component_name === "" && dependencies.length === 1
              }
            />
          )}
          {(dependencies?.length === 1 ||
            (dependency.id === dependencies.at(-1)?.id &&
              dependencies?.length < componentsList?.length - 1)) && (
            <Button
              onClick={handleAdd}
              icon="plus"
              primary
              disabled={
                dependency.component_name === "" ||
                dependency.min_version === ""
              }
            />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

type UpdateFirmwareDependenciesProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly dependentComponent: string;
  readonly dependentComponentFirmwareVersion: string;
  readonly loadingComponentList: boolean;
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
  readonly fillFirmwareTable: () => Promise<void>;
  readonly dependencies: DependencyType[];
};

function UpdateFirmwareDependencies(props: UpdateFirmwareDependenciesProps) {
  const {
    isOpen,
    onClose,
    dependentComponent,
    dependentComponentFirmwareVersion,
    loadingComponentList,
    componentsList,
    firmwareVersions,
  } = props;

  const [dependencies, setDependencies] = useState<DependencyType[]>([
    {
      id: 1,
      component_name: "",
      min_version: "",
    },
  ]);

  function onModalClose() {
    // Reset the state
    setDependencies([
      {
        id: 1,
        component_name: "",
        min_version: "",
      },
    ]);

    // Close the modal
    onClose();
  }

  const areDependenciesUpdated = () => {
    return (
      !isEqual(props.dependencies, dependencies) &&
      // case where dependencies are empty
      !(
        props.dependencies.length === 0 &&
        dependencies.length === 1 &&
        dependencies[0].component_name === ""
      )
    );
  };

  async function handleSubmit(e: React.MouseEvent) {
    e.preventDefault();

    if (!areDependenciesUpdated()) {
      beamtoast.error("No values have been updated!");
      return;
    } else if (
      dependencies.length > 1 &&
      dependencies.some((dependency) => dependency.component_name === "")
    ) {
      beamtoast.error("Component name cannot be empty!");
      return;
    }

    let dependencyList = dependencies.flatMap((dependency) => {
      if (dependency.component_name !== "") {
        return [
          {
            component_name: dependency.component_name,
            min_version: dependency.min_version,
          },
        ];
      } else {
        return [];
      }
    });

    if (
      dependencyList?.[0]?.component_name !== "" &&
      dependencyList.some((dependency) => dependency?.min_version === "")
    ) {
      for (let i = 0; i < dependencyList?.length; i++) {
        if (
          dependencyList?.[i]?.component_name !== "" &&
          dependencyList?.[i]?.min_version === ""
        ) {
          beamtoast.error(
            `Please select the minimum firmware version for "${dependencyList?.[i]?.component_name}" component`
          );
          break;
        }
      }
      return;
    } else {
      try {
        const res = await addOrUpdateFirmwareDependency(
          dependentComponent,
          dependentComponentFirmwareVersion,
          dependencyList
        );

        if (res) {
          beamtoast.success(
            `Dependency updated successfully for ${dependentComponent} version ${dependentComponentFirmwareVersion}`
          );
          await props.fillFirmwareTable();
          onModalClose();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (isOpen && props.dependencies?.length > 0)
      setDependencies(props.dependencies);
    else if (props.dependencies?.length === 0)
      setDependencies([{ id: 1, component_name: "", min_version: "" }]);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="dark"
      open={isOpen}
      onClose={onClose}
      size="small"
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>Update Dependency</Modal.Header>

      <Modal.Content>
        <p>
          {`Select the required component and the minimum firmware version that is required
          for the firmware version "${dependentComponentFirmwareVersion}" of ${dependentComponent} component to work properly.`}
        </p>

        <strong>Dependent Component</strong>
        <Table fixed style={{ border: "none" }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell
                width={8}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                <EditAnimatedMetaDropdown
                  placeholder="Select Component"
                  search
                  selection
                  defaultValue={dependentComponent}
                  options={componentsList.map((component) => ({
                    key: component,
                    value: component,
                    text: component,
                  }))}
                  disabled={true}
                  elementid={`select_component`}
                  style={{
                    marginBottom: "4px",
                    marginTop: "4px",
                  }}
                />
              </Table.Cell>

              <Table.Cell
                width={8}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                <EditAnimatedMetaDropdown
                  placeholder={"Select Firmware Version"}
                  fluid
                  search
                  selection
                  disabled={true}
                  options={firmwareVersions
                    .filter((firmwareVersion) => {
                      return (
                        dependentComponent ===
                        firmwareVersion.device_component_name
                      );
                    })
                    .map((version) => ({
                      key: version.version_number,
                      value: version.version_number,
                      text: version.version_number,
                    }))}
                  defaultValue={dependentComponentFirmwareVersion}
                  elementid={`select_version`}
                  style={{
                    marginBottom: "4px",
                    marginTop: "4px",
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong>Required Components</strong>
          {dependencies.length > 0 &&
            dependencies[0].component_name !== "" &&
            dependencies[0].min_version !== "" && (
              <p
                className={
                  "selectable-item underline hover-underline color-blue"
                }
                onClick={() => {
                  setDependencies([
                    { id: 1, component_name: "", min_version: "" },
                  ]);
                }}
              >
                Reset dependencies
              </p>
            )}
        </div>
        <Table fixed>
          <Table.Body>
            {dependencies.map((dependency) => (
              <ComponentWiseFirmwareRow
                key={dependency.component_name}
                dependentComponentWithVersion={{
                  id: -1,
                  component_name: dependentComponent,
                  min_version: dependentComponentFirmwareVersion,
                }}
                dependency={dependency}
                dependencies={dependencies}
                setDependencies={setDependencies}
                componentsList={componentsList}
                firmwareVersions={firmwareVersions}
                loadingComponentList={loadingComponentList}
              />
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel_button"
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submit_button"
          primary
          onClick={async (e) => {
            await handleSubmit(e);
          }}
        >
          <Icon name="checkmark" /> Save Dependency
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default UpdateFirmwareDependencies;
