import React from "react";

import {
  Modal,
  Divider,
  Label,
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  CheckboxProps,
  Radio,
  Accordion,
  Icon,
} from "semantic-ui-react";
import { User } from "../../../util";
import {
  TimeRange,
  RelativeTimeRange,
  SerializedTimeRange,
  deserializeTimeRange,
} from "./Datetime/TimeRange";
import { DateTimeDropdown } from "./Datetime/DateTimeDropdown";
import styled from "styled-components";
import { refreshIntervalOptions } from "./util";
import {
  fetchAllMetadataKeys,
  fetchDashboardShareOptions,
  Filters,
  editDashboard,
} from "../../../BytebeamClient";
import { DashboardFilters } from "./DashboardFilters";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/ext-searchbox.js";
import { Mixpanel } from "../common/MixPanel";
import { DashboardsInfo } from "./ViewDashboard";
import {
  EditAnimatedMetaDropdown,
  EditAnimatedMetaInput,
  EditMetaRow,
  StyledDashboardModalInput,
  StyledInputDiv,
} from "./Panel/util";
import ThemeSchema from "../../../theme/schema";
import { breakpoints } from "../../common/breakpoints";
import { beamtoast } from "../../common/CustomToast";
import { isJSONValid } from "../util";

export type DashboardShareOptions = {
  users: {
    name: string;
    id: string;
  }[];
  roles: {
    name: string;
    id: number;
  }[];
};

export enum DashboardType {
  FleetDashboard = "fleet",
  DeviceDashboard = "device",
}

export type DashboardMeta = {
  title: string;
  info: string;
  type: DashboardType;
  allowedFilterBys: string[];
  showMetadataKeys: string[];
  allowedGroupBys: string[];
  refreshInterval: number;
  owners: string[];
  viewers: string[];
  timeRange: SerializedTimeRange;
  showTimeRange: boolean;
  showRefreshInterval: boolean;
  presetFilters?: Filters;
  deviceFilters?: Filters;
  dashboardLinks?: string[];
  hideFromAdmins?: boolean;
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const EditDashboardText = styled.span`
  margin-left: auto;
  margin-right: 10px;
  display: block;
  float: right;
  color: ${(props) => props.theme.colors["link-text-color"]};
  cursor: pointer;
`;

const EditDashboardTextLeft = styled.span`
  margin-left: 10px;
  display: block;
  float: left;
  color: ${(props) => props.theme.colors["foreground-color"]};
`;

const StyledLabel = styled(Label)`
  @media (max-width: ${breakpoints.xs}px) {
    width: 36% !important;
  }
`;

type EditDashboardModalProps = {
  user: User;
  isOpen: boolean;
  onCancel: () => any;
  onSubmit: (meta: DashboardMeta) => any;
  dashboardMeta?: DashboardMeta;
  dashboardJSON?: object;
  dashboardId: number;
  refreshFunction: () => any;
  isAdmin: boolean;
  cloneDashboard?: boolean;
  allDashboards: DashboardsInfo[];
};

type EditDashboardModalState = {
  allowedGroupBys: string[];
  allowedFilterBys: string[];
  showMetadataKeys: string[];
  deviceMetaDataKeys: { key: string }[];
  metaDataKeysLoading: boolean;
  owners: string[];
  viewers: string[];
  dashboardLinks: string[];
  shareOptions: DashboardShareOptions;
  dashboardType: DashboardType;
  timeRange: TimeRange;
  refreshInterval: number;
  showTimeRange: boolean;
  showRefreshInterval: boolean;
  presetFilters: Filters;
  deviceFilters: Filters;
  editDashboardJSON: boolean;
  modifiedDashboardJSON: string;
  activeIndex: number;
  hideFromAdmins?: boolean;
};

export class EditDashboardModal extends React.Component<
  EditDashboardModalProps,
  EditDashboardModalState
> {
  inputRef = React.createRef<HTMLInputElement>();
  infoRef = React.createRef<HTMLInputElement>();

  constructor(props: EditDashboardModalProps) {
    super(props);

    const timeRange =
      props.dashboardMeta?.timeRange ??
      new RelativeTimeRange(5, "minutes").serialize();

    this.state = {
      allowedFilterBys: props.dashboardMeta?.allowedFilterBys ?? [],
      showMetadataKeys: props.dashboardMeta?.showMetadataKeys ?? [],
      allowedGroupBys: props.dashboardMeta?.allowedGroupBys ?? [],
      deviceMetaDataKeys: [],
      metaDataKeysLoading: true,
      owners: props.dashboardMeta?.owners ?? [`user:${this.props.user.id}`],
      viewers: props.dashboardMeta?.viewers ?? [],
      shareOptions: {
        roles: [],
        users: [],
      },
      dashboardType: props.dashboardMeta?.type ?? DashboardType.DeviceDashboard,
      timeRange: deserializeTimeRange(timeRange),
      refreshInterval: props.dashboardMeta?.refreshInterval ?? -1,
      showTimeRange: props.dashboardMeta?.showTimeRange ?? true,
      showRefreshInterval: props.dashboardMeta?.showRefreshInterval ?? true,
      presetFilters: props.dashboardMeta?.presetFilters ?? {},
      deviceFilters: props.dashboardMeta?.deviceFilters ?? {},
      hideFromAdmins: props.dashboardMeta?.hideFromAdmins ?? false,
      editDashboardJSON: false,
      modifiedDashboardJSON: JSON.stringify(props.dashboardJSON, null, 2) ?? "",
      dashboardLinks: props.dashboardMeta?.dashboardLinks ?? [],
      activeIndex: this.props.dashboardMeta?.title ? 0 : -1,
    };
  }

  async fetchDeviceMetaDataKeys() {
    try {
      const res = await fetchAllMetadataKeys();
      this.setState({ deviceMetaDataKeys: res, metaDataKeysLoading: false });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchShareOptions() {
    try {
      const res = await fetchDashboardShareOptions();
      this.setState({
        shareOptions: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    this.fetchDeviceMetaDataKeys();
    this.fetchShareOptions();
  }

  onGroupByChange(_e: any, data: DropdownProps) {
    this.setState({
      allowedGroupBys: data.value as string[],
    });
  }

  onFiltersChange(_e: any, data: DropdownProps) {
    this.setState({
      allowedFilterBys: data.value as string[],
    });
  }

  onPresetFilterChange(filterName: string, filterValues: string[]) {
    const filters = {
      ...this.state.presetFilters,
    };

    if (filterValues && filterValues.length > 0) {
      filters[filterName] = filterValues;
    } else {
      delete filters[filterName];
    }

    this.setState({ presetFilters: filters });
  }

  onDeviceFilterChange(filterName: string, filterValues: string[]) {
    const filters = {
      ...this.state.deviceFilters,
    };

    if (filterValues && filterValues.length > 0) {
      filters[filterName] = filterValues;
    } else {
      delete filters[filterName];
    }

    this.setState({ deviceFilters: filters });
  }

  onShowMetadataKeysChange(_e: any, data: DropdownProps) {
    this.setState({
      showMetadataKeys: data.value as string[],
    });
  }

  onOwnersChange(_e: any, data: DropdownProps) {
    this.setState({
      owners: data.value as string[],
    });
  }

  onDashboardLinksChange(_e: any, data: DropdownProps) {
    this.setState({
      dashboardLinks: data.value as string[],
    });
  }

  onViewersChange(_e: any, data: DropdownProps) {
    this.setState({
      viewers: data.value as string[],
    });
  }

  onDashboardTypeChange(_e: any, data: DropdownProps) {
    this.setState({
      dashboardType: data.value as DashboardType,
    });
  }

  onTimeRangeChange(timeRange: TimeRange) {
    this.setState({ timeRange });
  }

  onRefreshIntervalChange(_e: any, data: DropdownProps) {
    this.setState({
      refreshInterval: data.value as number,
    });
  }

  onShowTimeRangeChange(_e: any, data: CheckboxProps) {
    this.setState({
      showTimeRange: data.checked ?? true,
    });
  }

  onShowRefreshIntervalChange(_e: any, data: CheckboxProps) {
    this.setState({
      showRefreshInterval: data.checked ?? true,
    });
  }

  onHideFromAdminChange(_e: any, data: CheckboxProps) {
    this.setState({
      hideFromAdmins: data.checked ?? true,
    });
  }

  submit() {
    const title: string = this.inputRef.current?.value || "Untitled";
    const info: string = this.infoRef.current?.value ?? "";

    this.props.onSubmit({
      title: title?.trim(),
      info: info?.trim(),
      allowedFilterBys: this.state.allowedFilterBys,
      allowedGroupBys: this.state.allowedGroupBys,
      showMetadataKeys: this.state.showMetadataKeys,
      owners:
        this.state.owners.length > 0
          ? this.state.owners
          : [`user:${this.props.user.id}`],
      viewers: this.state.viewers,
      type: this.state.dashboardType,
      timeRange: this.state.timeRange.serialize(),
      refreshInterval: this.state.refreshInterval,
      showTimeRange: this.state.showTimeRange,
      showRefreshInterval: this.state.showRefreshInterval,
      hideFromAdmins: this.state.hideFromAdmins,
      presetFilters: this.state.presetFilters,
      deviceFilters: this.state.deviceFilters,
      dashboardLinks: this.state.dashboardLinks,
    });
  }

  async saveDashboardJSON() {
    if (!isJSONValid(this.state.modifiedDashboardJSON)) {
      beamtoast.error("Your Dashboard JSON is invalid");
    } else {
      const config = JSON.parse(this.state.modifiedDashboardJSON);
      const configKeys = Object.keys(config);

      if (configKeys.length === 0) {
        beamtoast.error("Dashboard JSON cannot be empty");
      } else if (
        !["panels", "gridLayouts", "dashboardMeta"].every((property) =>
          configKeys.includes(property)
        )
      ) {
        beamtoast.error("Dashboard JSON is missing required properties");
      } else {
        try {
          await editDashboard(this.props.dashboardId, { config });

          Mixpanel.track("Edited Dashboard JSON", {
            dashboardId: this.props.dashboardId,
          });

          this.setState({ editDashboardJSON: false });
          // for refreshing the preceding page after saving the dashboard
          this.props.refreshFunction();
          //for closing the normal edit dashboard modal
          this.props.onCancel();
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "Edit Dashboard JSON",
            error: JSON.stringify(e),
          });
          console.error(e);
        }
      }
    }
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { user } = this.props;
    const theme = user?.settings?.theme ?? "dark";

    const showMetadataOptions = this.state.deviceMetaDataKeys.map(({ key }) => {
      return {
        key: key,
        value: key,
        text: key,
      };
    });

    const filterByOptions = [
      { key: "id", value: "id", text: "id" },
      ...showMetadataOptions,
    ];

    const shareOptions: DropdownItemProps[] = [];
    this.state.shareOptions.roles.forEach((role) => {
      shareOptions.push({
        key: `role-${role.id}`,
        value: `role:${role.id}`,
        text: `Any user with role "${role.name}"`,
      });
    });

    this.state.shareOptions.users.forEach((user) => {
      shareOptions.push({
        key: `user-${user.id}`,
        value: `user:${user.id}`,
        text: `${user.name}`,
      });
    });

    const dashboardTypeOptions = [
      {
        key: "Fleet Dashboard",
        value: DashboardType.FleetDashboard,
        text: "Fleet Dashboard",
      },
      {
        key: "Device Dashboard",
        value: DashboardType.DeviceDashboard,
        text: "Device Dashboard",
      },
    ];

    let dashboardAction = "Create";
    if (this.props.dashboardMeta?.title) {
      if (this.props.cloneDashboard) dashboardAction = "Clone";
      else dashboardAction = "Edit";
    }

    const dashboardOptions = this.props.allDashboards
      .filter(
        (item) =>
          item.id !== this.props.dashboardId &&
          item.type === this.state.dashboardType
      )
      .map((item) => {
        const obj = {
          key: String(item.id),
          value: String(item.id),
          text: `${item.id} - ${item.title}`,
        };
        return obj;
      });

    let dashboardText = "-";
    if (
      dashboardTypeOptions.filter((dashboard) => {
        return dashboard.value === this.state.dashboardType;
      }).length > 0
    ) {
      dashboardText = dashboardTypeOptions.filter((dashboard) => {
        return dashboard.value === this.state.dashboardType;
      })[0]?.key;
    }

    return (
      <>
        {this.state.editDashboardJSON ? (
          <Modal
            centered={true}
            open={this.state.editDashboardJSON}
            size="small"
            className="dark"
            onClose={this.props.onCancel}
          >
            <Modal.Header>Edit Dashboard JSON</Modal.Header>
            <Modal.Content>
              <AceEditor
                height="500px"
                width="100%"
                mode="json"
                theme={
                  ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
                }
                name="custom-json"
                fontSize={14}
                value={this.state.modifiedDashboardJSON}
                onChange={(val) => {
                  this.setState({
                    modifiedDashboardJSON: val,
                  });
                }}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: false,
                  tabSize: 2,
                }}
                style={{
                  marginBottom: "16px",
                  borderRadius: "4px",
                  border: `${
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "ace-editor-border"
                    ]
                  }`,
                  boxShadow: `${
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "ace-editor-box-shadow"
                    ]
                  }`,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                onClick={() => this.setState({ editDashboardJSON: false })}
              >
                Cancel
              </Button>
              <Button primary onClick={() => this.saveDashboardJSON()}>
                Submit
              </Button>
            </Modal.Actions>
          </Modal>
        ) : (
          <Modal
            open={this.props.isOpen}
            size="small"
            className="dark"
            onClose={this.props.onCancel}
          >
            <Modal.Header>{dashboardAction} Dashboard </Modal.Header>
            <Modal.Content>
              <Row>
                <b>General</b>
              </Row>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus
                    defaultRef={this.inputRef}
                    defaultValue={this.props.dashboardMeta?.title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.infoRef}
                    defaultValue={this.props.dashboardMeta?.info}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Dashboard Type"
                    text={dashboardText}
                    search
                    selection
                    options={dashboardTypeOptions}
                    onChange={this.onDashboardTypeChange.bind(this)}
                    defaultValue={this.state.dashboardType}
                    elementid={"editDashboardType"}
                    disabled={dashboardAction !== "Create"}
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <Accordion inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold" }}>Advanced Settings </span>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  style={{ paddingLeft: "20px" }}
                >
                  <Row>
                    <b>Device Metadata</b>
                  </Row>

                  <Row>
                    <StyledDashboardModalInput labelPosition="left">
                      <StyledLabel>Allow filtering by</StyledLabel>

                      <Dropdown
                        placeholder="Filter By"
                        search
                        multiple
                        selection
                        options={filterByOptions}
                        defaultValue={
                          this.props.dashboardMeta?.allowedFilterBys
                        }
                        onChange={this.onFiltersChange.bind(this)}
                      />
                    </StyledDashboardModalInput>
                  </Row>

                  {this.state.dashboardType ===
                  DashboardType.DeviceDashboard ? (
                    <Row>
                      <StyledDashboardModalInput labelPosition="left">
                        <Label>Show Metadata</Label>

                        <Dropdown
                          placeholder="Select Metadata Keys"
                          search
                          multiple
                          selection
                          options={showMetadataOptions}
                          defaultValue={
                            this.props.dashboardMeta?.showMetadataKeys
                          }
                          onChange={this.onShowMetadataKeysChange.bind(this)}
                        />
                      </StyledDashboardModalInput>
                    </Row>
                  ) : null}

                  <Row>
                    <b>Default values for filters (optional)</b>
                  </Row>
                  <Row>
                    {this.state.allowedFilterBys.length > 0 ? (
                      <DashboardFilters
                        filterKeys={this.state.allowedFilterBys}
                        selectedFilters={
                          this.state.dashboardType ===
                          DashboardType.FleetDashboard
                            ? this.state.presetFilters
                            : this.state.deviceFilters
                        }
                        disabled={false}
                        onFilterSelected={
                          this.state.dashboardType ===
                          DashboardType.FleetDashboard
                            ? this.onPresetFilterChange.bind(this)
                            : this.onDeviceFilterChange.bind(this)
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </Row>

                  <Divider />

                  <Row>
                    <b>Time Range</b>
                  </Row>

                  <Row>
                    <StyledDashboardModalInput labelPosition="left">
                      <Label>Default Time Range</Label>
                      <DateTimeDropdown
                        showControls={false}
                        timeRange={this.state.timeRange}
                        onTimeRangeChange={this.onTimeRangeChange.bind(this)}
                        disabled={false}
                      />
                    </StyledDashboardModalInput>

                    <div style={{ display: "flex" }}>
                      <label style={{ paddingRight: "10px" }}>
                        {" "}
                        Allow changing time range{" "}
                      </label>
                      <Radio
                        toggle
                        checked={this.state.showTimeRange}
                        onChange={this.onShowTimeRangeChange.bind(this)}
                      />
                    </div>
                  </Row>

                  <Divider />

                  <Row>
                    <b>Refresh Interval</b>
                  </Row>

                  <Row>
                    <StyledDashboardModalInput labelPosition="left">
                      <Label>Default Refresh Interval</Label>
                      <Dropdown
                        style={{ fontWeight: "bold" }}
                        placeholder="Refresh Interval"
                        options={refreshIntervalOptions}
                        fluid
                        selection
                        defaultValue={this.state.refreshInterval}
                        onChange={this.onRefreshIntervalChange.bind(this)}
                      />
                    </StyledDashboardModalInput>

                    <div style={{ display: "flex" }}>
                      <span style={{ paddingRight: "10px" }}>
                        {" "}
                        Allow changing refresh interval{" "}
                      </span>
                      <Radio
                        toggle
                        checked={this.state.showRefreshInterval}
                        onChange={this.onShowRefreshIntervalChange.bind(this)}
                      />
                    </div>
                  </Row>

                  <Divider />

                  <Row>
                    <b>Dashboard Links</b>
                  </Row>

                  <Row>
                    <StyledDashboardModalInput labelPosition="left">
                      <StyledLabel>Links to other dashboards</StyledLabel>

                      <Dropdown
                        placeholder="Select Dashboard"
                        search
                        multiple
                        selection
                        options={dashboardOptions}
                        defaultValue={
                          this.props.dashboardMeta
                            ? this.props.dashboardMeta.dashboardLinks
                            : []
                        }
                        onChange={this.onDashboardLinksChange.bind(this)}
                      />
                    </StyledDashboardModalInput>
                  </Row>

                  <Divider />

                  <Row>
                    <b>Access Control</b>
                  </Row>

                  <Row>
                    <StyledDashboardModalInput labelPosition="left">
                      <Label>Owners</Label>

                      <Dropdown
                        placeholder="Owners"
                        search
                        multiple
                        selection
                        options={shareOptions}
                        defaultValue={
                          this.state.owners.length > 0 ? this.state.owners : []
                        }
                        onChange={this.onOwnersChange.bind(this)}
                      />
                    </StyledDashboardModalInput>
                  </Row>

                  <Row>
                    <StyledDashboardModalInput labelPosition="left">
                      <Label>Viewers</Label>

                      <Dropdown
                        placeholder="Viewers"
                        search
                        multiple
                        selection
                        options={shareOptions}
                        defaultValue={this.props.dashboardMeta?.viewers}
                        onChange={this.onViewersChange.bind(this)}
                      />
                    </StyledDashboardModalInput>
                  </Row>
                  {this.props.isAdmin &&
                  (dashboardAction === "Edit" ||
                    dashboardAction === "Clone") ? (
                    <Row>
                      <EditDashboardTextLeft>
                        <div style={{ display: "flex" }}>
                          <Radio
                            toggle
                            checked={this.state.hideFromAdmins}
                            onChange={this.onHideFromAdminChange.bind(this)}
                          />
                          <span style={{ paddingLeft: "10px" }}>
                            Hide from admins
                          </span>
                        </div>
                      </EditDashboardTextLeft>
                      <EditDashboardText>
                        <p
                          onClick={() =>
                            this.setState({ editDashboardJSON: true })
                          }
                        >
                          Advanced Edit
                        </p>
                      </EditDashboardText>
                    </Row>
                  ) : null}
                </Accordion.Content>
              </Accordion>
            </Modal.Content>
            <Modal.Actions>
              <Button secondary onClick={this.props.onCancel}>
                Cancel
              </Button>
              <Button primary onClick={this.submit.bind(this)}>
                Submit
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}
